import DataTableAction from "components/DataTableRow/dataTableAction";
import DataTableContent from "components/DataTableRow/dataTableContent";
import { useState } from "react";
import { tableCellTextFormat } from "utils/textOperation";
// import dateFormat from "utils/dateFormat";

export default function useRobe() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionOption, setActionOption] = useState("");
  const tableColumnsRobe = [
    { Header: "nom", accessor: "nom", align: "center" },
    { Header: "couleur", accessor: "couleur", align: "center" },
    { Header: "longueur", accessor: "longueur", align: "center" },
    { Header: "densite", accessor: "densite", align: "center" },
  ];
  const tableRowsRobe = (item) => {
    const row = {
      nom: <DataTableContent title1={tableCellTextFormat(item?.name, 50)} />,
      couleur: <DataTableContent title1={tableCellTextFormat(item?.color, 50)} />,
      longueur: <DataTableContent title1={tableCellTextFormat(item?.longueur, 50)} />,
      densite: <DataTableContent title1={tableCellTextFormat(item?.densite, 50)} />,
    };
    return row;
  };

  const onSelectedRow = (item, action) => {
    // console.log("onSelectedRow", item, action);
    setSelectedItem(item);
    setActionOption(action);
  };
  const renderDataTable = (listData) => {
    const arrData = [];
    if (listData.length > 0) {
      setColumns(tableColumnsRobe);
      if (listData[0]?.isEdit) {
        const columnsActions = [{ Header: "action", accessor: "action", align: "center" }];
        setColumns([...tableColumnsRobe, ...columnsActions]);
      }

      listData.map((i, idx, arr) => {
        let item = {};
        item = tableRowsRobe(i);
        arrData.push({
          ...item,
          ...{ action: <DataTableAction handleAction={onSelectedRow} item={i} /> },
        });
        if (idx === arr.length - 1) {
          setRows(arrData);
          return "success";
        }
        return "over";
      });
    } else {
      setRows(arrData);
      setColumns([]);
    }
  };
  return {
    renderDataTable,
    rows,
    columns,
    selectedItem,
    actionOption,
    onSelectedRow,
  };
}
