import { SUCCESS, EMPTY_VALUE, ERROR } from "constant/message";

import { useState } from "react";
import { ACTION_EDIT, ACTION_ADD } from "constant/dataTableActionConstant";
import { INPUT_COMPONENT, AUTO_COMPLETE_COMPONENT } from "constant/formComponentName";
import {
  TYPE_DIRECTION,
  TYPE_STRUCTURE_PRIVEE,
} from "layouts/donneeReference/rubrique/gestionEntite/constant";
import { ENDPOINT_GET_USER } from "constant/moduleInfo";
import useArrayOperation from "hooks/useArrayOperation";
import { SUPERVISEUR_ROLE } from "constant/role";

function useFormEntite() {
  const { sortArray } = useArrayOperation();
  const [isOpenForm, setisOpenForm] = useState(false);
  const [errorForm, setErrorForm] = useState({});
  const [valuesForm, setValuesForm] = useState({});
  const [titleForm, setTitleForm] = useState("");
  const handleFormState = (state) => {
    setisOpenForm(state);
  };
  const formEntite = (listUser, selectedItem) => {
    // console.log("selectedItem", selectedItem);
    const formConfig = [
      {
        name: "name",
        label: "Nom *",
        initialValue: selectedItem?.name ?? "",
        component: INPUT_COMPONENT,
        isVisible: true,
        isdisabled: false,
      },
      {
        name: "superviseur",
        label: "Responsable *",
        initialValue: listUser.find((i) => i.id === selectedItem?.superViseur?.id) ?? "",
        component: AUTO_COMPLETE_COMPONENT,
        list: listUser,
        isVisible: true,
        isdisabled: false,
      },
      {
        name: "superViseur",
        label: "Responsable *",
        initialValue: "",
        component: AUTO_COMPLETE_COMPONENT,
        list: listUser,
        isVisible: false,
        isdisabled: false,
      },
    ];
    // console.log("formConfigFerme", formConfig);
    return formConfig;
    // return [];
  };
  const getUser = async (getData) => {
    try {
      // const fetchData = await getData(`${ENDPOINT_GET_USER}/byRole/${RESPONSABLE_FERME}`);
      const endpoint = `${ENDPOINT_GET_USER}/${SUPERVISEUR_ROLE}`;
      /* if (type === TYPE_DIRECTION) {
        endpoint = `${ENDPOINT_GET_USER}/${ADMIN_DIRECTION_ROLE}`;
      } else if (type === TYPE_STRUCTURE_PRIVEE) {
        endpoint = `${ENDPOINT_GET_USER}/${ADMIN_DIRECTION_ROLE}`;
      } */
      const fetchData = await getData(endpoint);
      let result = [];
      if (fetchData !== ERROR) {
        const customArray = fetchData
          .filter((i) => i.enabled)
          .map((i) => {
            const item = {
              ...i,
              ...{ name: `${i.prenom} ${i.nom}, ${i.numTel}` },
            };
            return item;
          });
        result = sortArray(customArray, "name");
      }
      return result;
    } catch (error) {
      console.log("useferme get Lieu");
      throw error;
    }
  };
  const formConfigEntite = async (getData, type, selectedItem) => {
    const listUser = await getUser(getData, type);
    return formEntite(listUser, selectedItem);
  };
  const validateFormEntite = (name, value) => {
    // console.log("validateFormRole", name, value, value.length);
    let state = false;
    if (name === "name" && value === "") {
      state = false;
    } else if (
      (name === "superviseur" || name === "superViseur") &&
      Object.keys(value).length === 0
    ) {
      state = false;
    } else {
      state = true;
    }
    return state;
  };
  const valuesFormInitialization = (formConfig, actionOption, type) => {
    try {
      const initialValues = {};
      // const initialErrors = {};
      formConfig.forEach((config) => {
        initialValues[config.name] = config.initialValue || "";
        // initialErrors[config.name] = "";
      });
      setErrorForm({});
      setValuesForm(initialValues);
      if (actionOption === ACTION_ADD) {
        if (type === TYPE_STRUCTURE_PRIVEE) {
          setTitleForm("Nouvelle Structure Privée");
        } else if (type === TYPE_DIRECTION) {
          setTitleForm("Nouvelle Direction");
        }
      } else if (actionOption === ACTION_EDIT) {
        setTitleForm("Détails");
      }
    } catch (error) {
      console.log("valuesFormInitialization Entite error", error);
    }
  };

  const handleChangeFormValues = (event) => {
    try {
      const { name, value } = event.target;
      let data = {
        ...valuesForm,
        [name]: value,
      };
      if (name === "superviseur") {
        data = {
          ...data,
          superViseur: { numTel: value.numTel },
        };
      }

      setValuesForm(data);
    } catch (error) {
      console.log("handleChangeFormValues Entite error", error);
      alert(error);
    }
  };
  const validateForm = (formConfig) => {
    let data = "";
    try {
      const values = {};
      formConfig.forEach((config, idx, arr) => {
        if (config.isVisible) {
          const checkValue = validateFormEntite(config.name, valuesForm[config.name]);

          if (checkValue) {
            if (config.name in values) {
              delete values[config.name];
            }
          } else {
            values[config.name] = EMPTY_VALUE;
            // console.log("checkValue", values);
          }
        }
        if (idx === arr.length - 1) {
          // console.log("innnn", values);
          // handleAction();
          if (Object.keys(values).length === 0) {
            data = SUCCESS;
          }
        }
      });
      setErrorForm(values);
    } catch (error) {
      console.log("validateForm error", error);
    }
    return data;
  };
  return {
    handleFormState,
    isOpenForm,
    valuesFormInitialization,
    handleChangeFormValues,
    errorForm,
    valuesForm,
    setValuesForm,
    setErrorForm,
    validateForm,
    titleForm,
    formConfigEntite,
    validateFormEntite,
  };
}

export default useFormEntite;
