import {
  TITLE_DIRECTION,
  MODULE_ENTITE,
  MODULE_DONNEE_REF,
  PATH_DIRECTION,
  PATH_STRUCTURE_PRIVEE,
  TITLE_STRUCTURE_PRIVEE,
  // MODULE_ENTITE_TYPE,
  DEFAULT_PATH,
  PATH_ENTITE_DETAIL,
} from "constant/moduleInfo";
import React, { useEffect, useMemo, useState } from "react";
import servicesAPI from "services/servicesAPI";
import LayoutContext from "context/LayoutContext";
import LoadComponent from "components/LoadComponent";
import DataTableComponent from "components/DataTableComponent";
import useTableEntite from "layouts/donneeReference/rubrique/gestionEntite/hooks/useTableEntite";
import permissionFetcher from "utils/permissionFetcher";
import { useNavigate, useLocation } from "react-router-dom";
import { NO_DATA_FOUND, ERROR, NO_PERMISSION } from "constant/message";
import { ACTION_ADD, ACTION_DETAIL, ACTION_EDIT } from "constant/dataTableActionConstant";
import useArrayOperation from "hooks/useArrayOperation";
import { LAST_PATH } from "constant";
import { normalizeText } from "utils/textOperation";
import {
  TYPE_DIRECTION,
  TYPE_STRUCTURE_PRIVEE,
} from "layouts/donneeReference/rubrique/gestionEntite/constant";
import { checkRoleExist } from "utils/userInfos";
import { ADMIN_PAYS_ROLE } from "constant/role";
import useFormEntite from "./hooks/useFormEntite";

function GestionEntite() {
  const navigate = useNavigate();
  const location = useLocation();
  const { sortArray, customArrItem } = useArrayOperation();
  const [permissionModule, setPermissionModule] = useState([]);
  const [formConfig, setFormConfig] = useState([]);
  const [listData, setListData] = useState([]);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");

  const { isAlert, isLoading, message, alertSeverity, handleLoad, postData, getData, putData } =
    servicesAPI();
  const { columns, rows, renderDataTable, selectedItem, actionOption, onSelectedRow } =
    useTableEntite();
  const {
    isOpenForm,
    handleFormState,
    valuesForm,
    errorForm,
    valuesFormInitialization,
    handleChangeFormValues,
    titleForm,
    validateForm,
    formConfigEntite,
  } = useFormEntite();
  const getAllData = async (typeEntite) => {
    // const fetchData = await getData(`${MODULE_ENTITE_TYPE}=${typeEntite}`);
    const fetchData = await getData(MODULE_ENTITE);
    // const customArr = customArrItem(fetchData, MODULE_ENTITE);
    if (fetchData !== ERROR) {
      const customArr = customArrItem(fetchData, MODULE_ENTITE);
      const targetData = customArr.filter((i) => i.type === typeEntite);
      const result = sortArray(targetData, "name");

      if (result.length > 0) {
        // console.log("ddd", result);
        setListData(result);
        renderDataTable(result);
      } else {
        setListData([]);
        renderDataTable([]);
        let messageValue = "Auncune donnée trouvée";
        if (typeEntite === TYPE_DIRECTION) {
          messageValue = "Aucune Direction trouvée";
        } else if (typeEntite === TYPE_STRUCTURE_PRIVEE) {
          messageValue = "Aucune Structure Privée trouvée";
        }
        const loadData = {
          isAlert: true,
          message: messageValue,
          isLoading: false,
          alertSeverity: "error",
        };
        handleLoad(loadData);
      }
    }
  };
  const setForm = async (action) => {
    try {
      let loadData = {
        isAlert: false,
        message: "",
        isLoading: true,
        alertSeverity: "error",
      };
      handleLoad(loadData);
      let form = [];
      if (action === ACTION_ADD || action === ACTION_EDIT) {
        form = await formConfigEntite(getData, type, selectedItem);
      }
      setFormConfig(form);
      valuesFormInitialization(form, action, type);
      // console.log("ddddd", form, valuesForm);
      handleFormState(true);
      loadData = {
        isAlert: false,
        message: "",
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
    } catch (error) {
      console.error("setCompagnForm error", error);
    }
  };
  const handleDialogForm = (state, action) => {
    if (state) {
      setForm(action);
    } else {
      handleFormState(false);
      valuesFormInitialization(formConfig);
      onSelectedRow({}, "");
    }
  };
  const saveData = async () => {
    valuesForm.type = type;
    const dataForm = [valuesForm];
    console.log("ddd", dataForm);
    const fetchData = await postData(MODULE_ENTITE, dataForm);
    if (fetchData !== ERROR) {
      setTimeout(() => {
        handleDialogForm(false, "");
        getAllData(type);
      }, 200);
    }
  };
  const editData = async () => {
    valuesForm.type = type;
    let dataForm = valuesForm;
    if (actionOption === "qqq") {
      valuesForm.id = selectedItem.id;
      dataForm = [valuesForm];
    }
    // const fetchData = await putData(MODULE_ENTITE, dataForm);
    const fetchData = await putData(`Entite/${selectedItem.id}`, dataForm);
    if (fetchData !== ERROR) {
      setTimeout(() => {
        handleDialogForm(false, "");
        getAllData(type);
      }, 200);
    }
  };
  const getPermission = (typeEntite) => {
    const permissionArray = permissionFetcher(MODULE_DONNEE_REF);
    if (permissionArray.find((i) => i.name.includes(`Consulter${MODULE_DONNEE_REF}`))) {
      const isAdminGeneral = checkRoleExist(ADMIN_PAYS_ROLE);
      let permissionData = permissionArray;
      if (isAdminGeneral) {
        permissionData = [...permissionData, ...[{ name: `Insert${MODULE_DONNEE_REF}` }]];
      }
      setPermissionModule(permissionData);
      getAllData(typeEntite);
    } else {
      const loadData = {
        isAlert: true,
        message: NO_PERMISSION,
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
      setTimeout(() => {
        navigate(DEFAULT_PATH);
      }, 2000);
    }
  };
  const setData = () => {
    let typeEntite = "";
    if (location.pathname === PATH_DIRECTION) {
      setTitle(TITLE_DIRECTION);
      setType(TYPE_DIRECTION);
      typeEntite = TYPE_DIRECTION;
    } else if (location.pathname === PATH_STRUCTURE_PRIVEE) {
      setTitle(TITLE_STRUCTURE_PRIVEE);
      setType(TYPE_STRUCTURE_PRIVEE);
      typeEntite = TYPE_STRUCTURE_PRIVEE;
    }
    getPermission(typeEntite);
  };
  useEffect(() => {
    setListData([]);
    renderDataTable([]);
    setTitle("");
    setType("");
    setData();

    localStorage.setItem(LAST_PATH, location.pathname);
  }, [location.pathname]);
  const handleSearch = (value) => {
    if (value === "") {
      renderDataTable(listData);
    } else {
      /* const filteredData = listData.filter(
        (item) => item.name !== null && item.name.toLowerCase().includes(value.toLowerCase())
      ); */
      const searchTerm = normalizeText(value);
      const filteredData = listData.filter((item) => {
        return (
          normalizeText(item?.name).includes(searchTerm) ||
          normalizeText(item?.superViseur?.nom).includes(searchTerm) ||
          normalizeText(item?.superViseur?.prenom).includes(searchTerm) ||
          normalizeText(item?.superViseur?.numTel).includes(searchTerm)
        );
      });
      if (filteredData.length > 0) {
        renderDataTable(filteredData);
      } else {
        const loadData = {
          isAlert: true,
          message: NO_DATA_FOUND,
          isLoading: false,
          alertSeverity: "error",
        };
        handleLoad(loadData);
      }
    }
  };
  const refreshData = () => {
    getAllData(type);
  };

  useEffect(() => {
    if (Object.keys(selectedItem).length !== 0) {
      // console.log("qqq", selectedItem);
      //  handleDialogForm(true, ACTION_DETAIL);
      if (actionOption === ACTION_DETAIL) {
        //  handleDialogForm(true, ACTION_DETAIL);
        // navigate(PATH_DETAIL_USER, { state: { selectedData: selectedItem } });
        navigate(PATH_ENTITE_DETAIL, {
          state: { selectedData: { ...selectedItem } },
        });
      } else if (actionOption === ACTION_EDIT) {
        handleDialogForm(true, ACTION_EDIT);
      }
    }
  }, [selectedItem]);
  const contextValue = useMemo(() => {
    const value = {
      title,
      columns,
      rows,
      permissionModule,
      saveData,
      editData,
      module: MODULE_DONNEE_REF,
      isOpenForm,
      handleDialogForm,
      formConfig,
      valuesForm,
      errorForm,
      handleChangeFormValues,
      titleForm,
      validateForm,
      selectedItem,
      actionOption,
      onSelectedRow,
      handleSearch,
      refreshData,
    };
    return value;
  }, [
    columns,
    rows,
    permissionModule,
    isOpenForm,
    formConfig,
    valuesForm,
    errorForm,
    selectedItem,
    actionOption,
  ]);

  return (
    <div>
      <LayoutContext.Provider value={contextValue}>
        <DataTableComponent />
      </LayoutContext.Provider>
      <LoadComponent loadData={{ isAlert, isLoading, message, alertSeverity, handleLoad }} />
    </div>
  );
}

export default GestionEntite;
