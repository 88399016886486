// import camel from "assets/images/icons/iconDashboard/camel.png";
import aulacode from "assets/images/icons/iconDashboard/aulacode.png";
import cat from "assets/images/icons/iconDashboard/cat.png";
import cow from "assets/images/icons/iconDashboard/cow.png";
import dog from "assets/images/icons/iconDashboard/dog.png";
import fleshChicken from "assets/images/icons/iconDashboard/fleshChicken.png";
import goat from "assets/images/icons/iconDashboard/goat.png";
import hen from "assets/images/icons/iconDashboard/hen.png";
import horse from "assets/images/icons/iconDashboard/horse.png";
import pig from "assets/images/icons/iconDashboard/pig.png";
import rabbit from "assets/images/icons/iconDashboard/rabbit.png";
import sheep from "assets/images/icons/iconDashboard/sheep.png";
import camel from "assets/images/icons/iconDashboard/camel.png";

export const ESPECE_CHIEN = "Chiens";
export const ESPECE_CHAT = "Chats";
export const ESPECE_CHEVAL = "Chevaux";
export const ESPECE_BOVIN = "Bovins";
export const ESPECE_CAPRIN = "Caprins";
export const ESPECE_OVIN = "Ovins";
export const ESPECE_PORCIN = "Porcins";
export const ESPECE_AULACODE = "Aulacodes";
export const ESPECE_LAPIN = "Lapins";
export const ESPECE_POULET_CHAIR = "Poulets de chair";
export const ESPECE_PONDEUSE = "Pondeuses";
export const ESPECE_CAMEL = "Camelins";

export const IMG_ESPECE_CHIEN = dog;
export const IMG_ESPECE_CHAT = cat;
export const IMG_ESPECE_CHEVAL = horse;
export const IMG_ESPECE_BOVIN = cow;
export const IMG_ESPECE_CAPRIN = goat;
export const IMG_ESPECE_OVIN = sheep;
export const IMG_ESPECE_PORCIN = pig;
export const IMG_ESPECE_AULACODE = aulacode;
export const IMG_ESPECE_LAPIN = rabbit;
export const IMG_ESPECE_POULET_CHAIR = fleshChicken;
export const IMG_ESPECE_PONDEUSE = hen;

export const LIST_ESPECE_ELEVAGE = [
  { espece: ESPECE_BOVIN, img: cow, backgroundColor: "rgba(34,139,34,1)" },
  { espece: ESPECE_CAPRIN, img: goat, backgroundColor: "rgba(241, 90, 34, 1)" },
  { espece: ESPECE_OVIN, img: sheep, backgroundColor: "rgba(90, 34, 139, 1)" },
  /* { espece: ESPECE_PORCIN, img: pig, backgroundColor: "rgba(140, 133, 20, 1)" },
  { espece: ESPECE_AULACODE, img: aulacode, backgroundColor: "rgba(110, 33, 20, 1)" },
  { espece: ESPECE_LAPIN, img: rabbit, backgroundColor: "rgba(70, 41, 15, 1)" },
  { espece: ESPECE_POULET_CHAIR, img: fleshChicken, backgroundColor: "rgba(330, 13, 17, 1)" },
  { espece: ESPECE_PONDEUSE, img: hen, backgroundColor: "rgba(25, 307, 45, 1)" }, */
  { espece: ESPECE_CAMEL, img: camel, bgColor: "rgba(15, 10, 222, 1)" },
];
export const LIST_ESPECE_ELEVAGE_CIV = [
  { espece: ESPECE_BOVIN, img: cow, backgroundColor: "rgba(34,139,34,1)" },
  { espece: ESPECE_CAPRIN, img: goat, backgroundColor: "rgba(241, 90, 34, 1)" },
  { espece: ESPECE_OVIN, img: sheep, backgroundColor: "rgba(90, 34, 139, 1)" },
  { espece: ESPECE_PORCIN, img: pig, backgroundColor: "rgba(140, 133, 20, 1)" },
  { espece: ESPECE_AULACODE, img: aulacode, backgroundColor: "rgba(110, 33, 20, 1)" },
  { espece: ESPECE_LAPIN, img: rabbit, backgroundColor: "rgba(70, 41, 15, 1)" },
  { espece: ESPECE_POULET_CHAIR, img: fleshChicken, backgroundColor: "rgba(330, 13, 17, 1)" },
  { espece: ESPECE_PONDEUSE, img: hen, backgroundColor: "rgba(25, 307, 45, 1)" },
  // { espece: ESPECE_CAMEL, img: camel, bgColor: "rgba(15, 10, 222, 1)" },
];
export const LIST_ESPECE_TRANSHUMANCE = [
  { espece: ESPECE_BOVIN, img: cow, backgroundColor: "rgba(34,139,34,1)" },
  { espece: ESPECE_CAPRIN, img: goat, backgroundColor: "rgba(241, 90, 34, 1)" },
  { espece: ESPECE_OVIN, img: sheep, backgroundColor: "rgba(90, 34, 139, 1)" },
];
export const LIST_ESPECE_COMPAGNIE = [
  { espece: ESPECE_CHIEN, img: dog, backgroundColor: "rgba(34,139,34,1)" },
  { espece: ESPECE_CHAT, img: cat, backgroundColor: "rgba(241, 90, 34, 1)" },
  { espece: ESPECE_CHEVAL, img: horse, backgroundColor: "rgba(30, 123, 150, 1)" },
];
