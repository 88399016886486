import { ERROR } from "constant/message";
import { MODULE_ROLE } from "constant/moduleInfo";
import { SUPER_ADMIN_ROLE, roleFonction } from "constant/role";

import useArrayOperation from "hooks/useArrayOperation";

const { sortArray } = useArrayOperation();
const getRole = async (getData) => {
  const fetchData = await getData(MODULE_ROLE);
  let result = [];
  if (fetchData !== ERROR) {
    const filterData = fetchData.filter((i) => i.roleName !== SUPER_ADMIN_ROLE);

    const roleFonctionArr = filterData
      .map((x) => {
        const fonctionData = roleFonction.find((y) => y.role === x.roleName);
        /* console.log("fonctionData cc", fonctionData);
      let item;
      if (fonctionData) {
        item = {
          ...x,
          ...{ fonction: fonctionData?.fonction ?? x.roleName, niveau: fonctionData?.niveau ?? 6 },
        };
      }
      return item ?? null; */
        if (fonctionData) {
          return {
            ...x,
            fonction: fonctionData.fonction ?? x.roleName,
            niveau: fonctionData.niveau ?? 6,
          };
        }
        // Ne rien retourner si fonctionData est undefined
        return undefined;
      })
      .filter(Boolean);
    result = sortArray(roleFonctionArr, "fonction");
  }
  return result;
};
export default getRole;
