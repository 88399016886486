import { ERROR } from "constant/message";
import dateFormat from "utils/dateFormat";
import { searchUser } from "./userService";

const getMouvement = async (ApiService, idAnimal) => {
  const fetchData = await ApiService.getData(`animals/${idAnimal}/movements`);
  let result = [];
  if (fetchData !== ERROR) {
    // result = sortArray(fetchData, "name");
    result = await Promise.all(
      fetchData.map(async (i) => {
        let motif = "";
        if (i.fieldName === "Eleveur") {
          motif = "Changement d'éleveur";
        } else if (i.fieldName === "Proprietaire" || i.fieldName === "Properietaire") {
          motif = "Changement de propriétaire";
        } else if (i.fieldName === "Ferme") {
          motif = "Changement d'exploitation";
        }
        let { previousValue, currentValue } = i;

        if (
          i.fieldName === "Eleveur" ||
          i.fieldName === "Proprietaire" ||
          i.fieldName === "Properietaire"
        ) {
          const previous = await searchUser(ApiService.postData, i.previousValue);
          if (Array.isArray(previous) && previous.length > 0) {
            previousValue = `${previous[0]?.prenom} ${previous[0]?.nom}`;
          }
          const current = await searchUser(ApiService.postData, i.currentValue);
          if (Array.isArray(current) && current.length > 0) {
            currentValue = `${current[0]?.prenom} ${current[0]?.nom}`;
          }
        }
        let dateMouvement = i.timestamp;
        if (i?.timestamp === null) {
          dateMouvement = "Non définie";
        } else {
          dateMouvement = dateFormat(new Date(i?.timestamp));
        }

        const item = {
          ...i,
          ...{ motif, previousValue, currentValue, dateMouvement },
        };
        return item;
      })
    );
    // console.log("aaaasss", result);
  }
  return result;
};
export default getMouvement;
