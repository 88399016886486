import { useState } from "react";
import { apiUrlMr, apiUrlCiv } from "constant/apiUrl.ts";
// import apiUrl from "constant/apiUrl";
import axios from "axios";
import { ERROR } from "constant/message";
import { USER_DATA_STORAGE, CURRENT_PAYS } from "constant";

export default function servicesAPI() {
  // const { handleLoad } = LoadingManager as { handleLoad: (value: loadObject) => void };
  // const loadingManager = LoadingManager();
  const [isLoading, setIsLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const timeOut = 10000;
  let apiUrl = apiUrlMr;
  if (CURRENT_PAYS === "Côte D'Ivoire") {
    apiUrl = apiUrlCiv;
  }
  const fileURL = `${apiUrl}/file`;

  function handleLoad(value) {
    setIsAlert(value.isAlert);
    setMessage(value.message);
    setAlertSeverity(value.alertSeverity);
    // setIsLoading(false);
    setIsLoading(value.isLoading);
  }
  function handleError(error) {
    let errorMessage = error?.response?.data?.errorDetails ?? error?.message;
    if (error?.response?.status === 403) {
      errorMessage = "Une double connexion a été détectée. Veuillez vous reconnecter";
    } else if (error?.message === "Network Error") {
      errorMessage = "Vérifier votre connexion internet";
    } else if (error?.code === "ECONNABORTED") {
      errorMessage = "Votre connexion internet est instable ";
    } else if (errorMessage.includes("unique_num_tel_constraint")) {
      errorMessage = "Ce numéro de téléphone est attribué à un autre utilisateur";
    } else if (JSON.stringify(error?.response?.data)?.includes("Current password is incorrect.")) {
      errorMessage = "Le mot de passe actuel est incorrect";
    } else if (
      JSON.stringify(error?.response?.data.msg)?.includes("JWT expired.") ||
      JSON.stringify(error?.response?.data.msg)?.includes("token revoked or expired")
    ) {
      errorMessage = "Session expirée, Veuillez vous reconnecter";
    }
    console.error("sss", error);
    const loadData = {
      isAlert: true,
      message: errorMessage,
      isLoading: false,
      alertSeverity: "error",
    };
    handleLoad(loadData);
  }
  // Récupération d'une liste de donnée en fonction de l'endpoint
  const getData = (endPointURL) => {
    let loadData = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "",
    };
    let resultData = null;
    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        const config = {
          timeout: 10000,
          headers: { Authorization: `Bearer ${accessToken.access_token}` },
        };
        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .get(`${apiUrl}/${endPointURL}`, config)
          .then((response) => {
            const result = response.data;
            let returnData = [];
            // Si le résultat est un tableau de donnée
            if (Array.isArray(result) && result.length > 0) {
              loadData = {
                isAlert: false,
                message: "",
                isLoading: false,
                alertSeverity: "success",
              };
              handleLoad(loadData);
              returnData = result;
            } else {
              loadData = {
                isAlert: false,
                message: "Aucune Données Trouvées",
                isLoading: false,
                alertSeverity: "error",
              };
              handleLoad(loadData);
              returnData = result;
            }

            return returnData;
          })
          .catch((error) => {
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return ERROR;
    }
    return resultData;
  };
  const getDataUser = (endPointURL) => {
    let loadData = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "",
    };
    let resultData = null;
    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        const config = {
          timeout: 10000,
          headers: { Authorization: `Bearer ${accessToken.access_token}` },
        };
        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .get(`${apiUrl}/${endPointURL}`, config)
          .then((response) => {
            const result = response.data;
            let returnData = null;
            // Si le résultat est un tableau de donnée
            // f (Array.isArray(result) && result.length > 0) {
            loadData = {
              isAlert: false,
              message: "",
              isLoading: false,
              alertSeverity: "success",
            };
            handleLoad(loadData);
            returnData = result;

            return returnData;
          })
          .catch((error) => {
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return ERROR;
    }
    return resultData;
  };
  const getDataDashboard = (endPointURL) => {
    let loadData = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "",
    };
    let resultData = null;
    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        const config = {
          timeout: 10000,
          headers: { Authorization: `Bearer ${accessToken.access_token}` },
        };

        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .get(`${apiUrl}/${endPointURL}`, config)
          .then((response) => {
            const result = response.data;
            let returnData = null;
            // Si le résultat est un tableau de donnée

            // f (Array.isArray(result) && result.length > 0) {
            loadData = {
              isAlert: false,
              message: "",
              isLoading: false,
              alertSeverity: "success",
            };
            handleLoad(loadData);
            returnData = result;

            return returnData;
          })
          .catch((error) => {
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return ERROR;
    }
    return resultData;
  };
  // Enregistrement de donnée en fonction de l'endpoint
  const postData = (endPointURL, dataPost) => {
    let loadData = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "",
    };
    let resultData = null;

    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        const config = {
          timeout: 10000,
          headers: { Authorization: `Bearer ${accessToken.access_token}` },
        };

        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .post(`${apiUrl}/${endPointURL}`, dataPost, config)
          .then((response) => {
            const result = response.data;
            /* let returnData = null;
            // Si le résultat est un tableau de donnée
            if (Array.isArray(result) && result.length > 0) {
              loadData = {
                isAlert: false,
                message: "",
                isLoading: false,
                alertSeverity: "success",
              };
              handleLoad(loadData);
              returnData = result;
            } else {
              loadData = {
                isAlert: true,
                message: "Aucune Données Trouvées",
                isLoading: false,
                alertSeverity: "error",
              };
              handleLoad(loadData);
              returnData = "noData";
            }

            return returnData; */

            loadData = { isAlert: false, message: "", isLoading: false, alertSeverity: "info" };
            handleLoad(loadData);

            return result;
          })
          .catch((error) => {
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return ERROR;
    }
    return resultData;
  };
  const putData = (endPointURL, dataPost) => {
    let loadData = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "",
    };
    let resultData = null;

    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        const config = {
          timeout: 10000,
          headers: { Authorization: `Bearer ${accessToken.access_token}` },
        };

        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .put(`${apiUrl}/${endPointURL}`, dataPost, config)
          .then((response) => {
            const result = response.data;
            /* let returnData = null;
            // Si le résultat est un tableau de donnée
            if (Array.isArray(result) && result.length > 0) {
              loadData = {
                isAlert: false,
                message: "",
                isLoading: false,
                alertSeverity: "success",
              };
              handleLoad(loadData);
              returnData = result;
            } else {
              loadData = {
                isAlert: true,
                message: "Aucune Données Trouvées",
                isLoading: false,
                alertSeverity: "error",
              };
              handleLoad(loadData);
              returnData = "noData";
            }

            return returnData; */
            loadData = { isAlert: false, message: "", isLoading: false, alertSeverity: "info" };
            handleLoad(loadData);

            return result;
          })
          .catch((error) => {
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return ERROR;
    }
    return resultData;
  };

  const downloadFile = (fileName, type) => {
    let loadData;
    let resultData;

    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      // if (userToken !== null) {
      const accessToken = JSON.parse(userToken);
      loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
      handleLoad(loadData);
      const config = {
        timeout: 10000,
        headers: {
          Authorization: `Bearer ${accessToken.access_token}`,
          "Content-Type": "application/pdf",
        },
        responseType: "arraybuffer",
      };

      resultData = axios
        // .get(`${fileURL}/download/byFileName?fileName=${fileName}.pdf&businessType=${type}`, config)
        .get(`${fileURL}/download/${type}/byFileName?fileName=${fileName}.pdf`, config)
        .then((response) => {
          const result = response.data;

          loadData = {
            isAlert: false,
            message: "Fichier Disponible",
            isLoading: false,
            alertSeverity: "success",
          };
          handleLoad(loadData);
          return result;
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            loadData = {
              isAlert: false,
              message: "Fichier indisponible",
              isLoading: false,
              alertSeverity: "error",
            };
            handleLoad(loadData);
          } else {
            handleError(error);
          }
          return ERROR;
        });
      //  }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      handleLoad(loadData);
      return "error";
    }
    return resultData;
  };
  // Upload du fichier
  const uploadFile = (file, name, type) => {
    let loadData;
    let resultData;

    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken.access_token}`,
            "Content-Type": "application/pdf",
          },
        };
        const formData = new FormData();
        formData.append("file", file, `${name}.pdf`);
        resultData = axios
          .post(`${fileURL}/${type}`, formData, config)
          .then((response) => {
            const result = response.data;

            loadData = {
              isAlert: true,
              message: "Opération effectuée avec succès",
              isLoading: false,
              alertSeverity: "success",
            };
            handleLoad(loadData);
            return result;
          })
          .catch((error) => {
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      handleLoad(loadData);
      return "error";
    }
    return resultData;
  };

  const getAllMaladie = (endPointURL) => {
    let loadData;
    let resultData;
    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const userData = JSON.parse(userToken);
        const config = {
          timeout: timeOut,
          headers: { Authorization: `Bearer ${userData.access_token}` },
        };

        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .get(`${apiUrl}/${endPointURL}`, config)
          .then((response) => {
            const result = response.data;
            let returnData;
            // Si le résultat est un tableau de donnée
            if (Array.isArray(result)) {
              returnData = result;
            }
            // }
            loadData = {
              isAlert: false,
              message: "",
              isLoading: false,
              alertSeverity: "success",
            };
            handleLoad(loadData);
            return returnData;
          })
          .catch((error) => {
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return "error";
    }
    return resultData;
  };

  const deleteData = (endPointURL) => {
    let resultData;
    let loadData = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "error",
    };

    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        const config = {
          timeout: timeOut,
          headers: { Authorization: `Bearer ${accessToken.access_token}` },
        };

        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .delete(`${apiUrl}/${endPointURL}`, config)
          .then((response) => {
            loadData = {
              isAlert: true,
              message: "Opération effectuée avec succès",
              isLoading: false,
              alertSeverity: "success",
            };
            handleLoad(loadData);
            const result = response.data;
            return result;
          })
          .catch((error) => {
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      handleLoad(loadData);
      return ERROR;
    }
    return resultData;
  };
  return {
    getData,
    isLoading,
    isAlert,
    message,
    alertSeverity,
    handleLoad,
    postData,
    getDataUser,
    getDataDashboard,
    putData,
    downloadFile,
    uploadFile,
    getAllMaladie,
    deleteData,
  };
}
