import { SUCCESS, EMPTY_VALUE } from "constant/message";

import { useState } from "react";
import { ACTION_EDIT, ACTION_ADD } from "constant/dataTableActionConstant";
import { INPUT_COMPONENT } from "constant/formComponentName";

function useFormRobe() {
  const [isOpenForm, setisOpenForm] = useState(false);
  const [errorForm, setErrorForm] = useState({});
  const [valuesForm, setValuesForm] = useState({});
  const [titleForm, setTitleForm] = useState("");
  const handleFormState = (state) => {
    setisOpenForm(state);
  };
  const formRobe = (selectedItem) => {
    const formConfig = [
      {
        name: "name",
        label: "Nom *",
        initialValue: selectedItem?.name ?? "",
        component: INPUT_COMPONENT,
        isVisible: true,
        isdisabled: false,
      },
      {
        name: "color",
        label: "Couleur *",
        initialValue: selectedItem?.color ?? "",
        component: INPUT_COMPONENT,
        isVisible: true,
        isdisabled: false,
      },
      {
        name: "longueur",
        label: "Longueur *",
        initialValue: selectedItem?.longueur ?? "",
        component: INPUT_COMPONENT,
        isVisible: true,
        isdisabled: false,
      },
      {
        name: "texture",
        label: "Texture *",
        initialValue: selectedItem?.texture ?? "",
        component: INPUT_COMPONENT,
        isVisible: true,
        isdisabled: false,
      },
      {
        name: "densite",
        label: "Densite *",
        initialValue: selectedItem?.densite ?? "",
        component: INPUT_COMPONENT,
        isVisible: true,
        isdisabled: false,
      },
    ];
    // console.log("formConfigFerme", formConfig);
    return formConfig;
    // return [];
  };
  const formConfigRobe = async (selectedItem) => {
    return formRobe(selectedItem);
  };
  const validateFormRobe = (name, value) => {
    if (name === "7q_") {
      console.log("name", name);
    }
    let state = false;
    if (value === "") {
      state = false;
    } else {
      state = true;
    }
    return state;
  };
  const valuesFormInitialization = (formConfig, actionOption) => {
    try {
      const initialValues = {};
      // const initialErrors = {};
      formConfig.forEach((config) => {
        initialValues[config.name] = config.initialValue || "";
        // initialErrors[config.name] = "";
      });
      setErrorForm({});
      setValuesForm(initialValues);
      if (actionOption === ACTION_ADD) {
        setTitleForm("Nouvelle Robe");
      } else if (actionOption === ACTION_EDIT) {
        setTitleForm("Détails");
      }
    } catch (error) {
      console.log("valuesFormInitialization Robe error", error);
    }
  };

  const handleChangeFormValues = (event) => {
    try {
      const { name, value } = event.target;
      const data = {
        ...valuesForm,
        [name]: value,
      };

      setValuesForm(data);
    } catch (error) {
      console.log("handleChangeFormValues Robe error", error);
      alert(error);
    }
  };
  const validateForm = (formConfig) => {
    let data = "";
    try {
      const values = {};
      formConfig.forEach((config, idx, arr) => {
        if (config.isVisible) {
          const checkValue = validateFormRobe(config.name, valuesForm[config.name]);

          if (checkValue) {
            if (config.name in values) {
              delete values[config.name];
            }
          } else {
            values[config.name] = EMPTY_VALUE;
            // console.log("checkValue", values);
          }
        }
        if (idx === arr.length - 1) {
          // console.log("innnn", values);
          // handleAction();
          if (Object.keys(values).length === 0) {
            data = SUCCESS;
          }
        }
      });
      setErrorForm(values);
    } catch (error) {
      console.log("validateForm error", error);
    }
    return data;
  };
  return {
    handleFormState,
    isOpenForm,
    valuesFormInitialization,
    handleChangeFormValues,
    errorForm,
    valuesForm,
    setValuesForm,
    setErrorForm,
    validateForm,
    titleForm,
    formConfigRobe,
    validateFormRobe,
  };
}

export default useFormRobe;
