import DataTableAction from "components/DataTableRow/dataTableAction";
import DataTableContent from "components/DataTableRow/dataTableContent";
import { useState } from "react";
import { tableCellTextFormat } from "utils/textOperation";

export default function useLieu() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionOption, setActionOption] = useState("");
  const tableColumnsLieu = [
    { Header: "code", accessor: "codeLieu", align: "center" },
    { Header: "region", accessor: "region", align: "center" },
    { Header: "departement", accessor: "departement", align: "center" },
    { Header: "commune", accessor: "commune", align: "center" },
  ];
  const tableRowsLieu = (item) => {
    const codeLieu = `${item?.codeRegion}${item?.codeDepart}${item?.codeCommune}`;
    const row = {
      commune: <DataTableContent title2={tableCellTextFormat(item?.commune, 50)} />,
      departement: <DataTableContent title2={tableCellTextFormat(item?.departement, 50)} />,
      region: <DataTableContent title2={tableCellTextFormat(item?.region, 50)} />,
      // codeLieu: <DataTableContent title2={tableCellTextFormat(item?.localite, 50)} />,
      codeLieu: <DataTableContent title2={tableCellTextFormat(codeLieu, 50)} />,
    };
    return row;
  };

  const onSelectedRow = (item, action) => {
    // console.log("onSelectedRow", item, action);
    setSelectedItem(item);
    setActionOption(action);
  };
  const renderDataTable = (listData) => {
    const arrData = [];
    if (listData.length > 0) {
      // const columnsActions = [{ Header: "action", accessor: "action", align: "center" }];

      // setColumns([...tableColumnsLieu, ...columnsActions]);
      setColumns(tableColumnsLieu);

      listData.map((i, idx, arr) => {
        let item = {};
        item = tableRowsLieu(i);
        arrData.push({
          ...item,
          ...{ action: <DataTableAction handleAction={onSelectedRow} item={i} /> },
        });
        if (idx === arr.length - 1) {
          setRows(arrData);
          return "success";
        }
        return "over";
      });
    } else {
      setRows(arrData);
      setColumns([]);
    }
  };
  return {
    renderDataTable,
    rows,
    columns,
    selectedItem,
    actionOption,
    onSelectedRow,
  };
}
