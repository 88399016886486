import { TITLE_ROBE, MODULE_ROBE, MODULE_DONNEE_REF, DEFAULT_PATH } from "constant/moduleInfo";
import React, { useEffect, useMemo, useState } from "react";
import servicesAPI from "services/servicesAPI";
import LayoutContext from "context/LayoutContext";
import LoadComponent from "components/LoadComponent";
import DataTableComponent from "components/DataTableComponent";
import useTableRobe from "layouts/donneeReference/rubrique/gestionRobe/hooks/useTableRobe";
import permissionFetcher from "utils/permissionFetcher";
import { useNavigate, useLocation } from "react-router-dom";
import { NO_DATA_FOUND, ERROR, NO_PERMISSION } from "constant/message";
import { ACTION_ADD, ACTION_EDIT } from "constant/dataTableActionConstant";
import { LAST_PATH } from "constant";
import useArrayOperation from "hooks/useArrayOperation";
import { normalizeText } from "utils/textOperation";
import getRobe from "services/robeService";
import useFormRobe from "./hooks/useFormRobe";

function GestionRobe() {
  const navigate = useNavigate();
  const location = useLocation();
  const [permissionModule, setPermissionModule] = useState([]);
  const [formConfig, setFormConfig] = useState([]);
  const [listData, setListData] = useState([]);
  const { isAlert, isLoading, message, alertSeverity, handleLoad, postData, getData, putData } =
    servicesAPI();
  const { columns, rows, renderDataTable, selectedItem, actionOption, onSelectedRow } =
    useTableRobe();
  const {
    isOpenForm,
    handleFormState,
    valuesForm,
    errorForm,
    valuesFormInitialization,
    handleChangeFormValues,
    titleForm,
    validateForm,
    formConfigRobe,
  } = useFormRobe();
  const { customArrItem } = useArrayOperation();
  const getAllData = async () => {
    // const fetchData = await getData(MODULE_Robe);
    const fetchData = await getRobe(getData);
    if (fetchData !== ERROR) {
      const customArr = customArrItem(fetchData, MODULE_ROBE);
      const result = customArr;
      // console.log("ddd", result);
      setListData(result);
      renderDataTable(result);
      if (result.length === 0) {
        const loadData = {
          isAlert: true,
          message: "Aucune robe trouvée",
          isLoading: false,
          alertSeverity: "error",
        };
        handleLoad(loadData);
      }
    }
  };
  const setForm = async (action) => {
    try {
      let loadData = {
        isAlert: false,
        message: "",
        isLoading: true,
        alertSeverity: "error",
      };
      handleLoad(loadData);
      let form = [];
      if (action === ACTION_ADD || action === ACTION_EDIT) {
        form = await formConfigRobe(selectedItem);
      }
      setFormConfig(form);
      valuesFormInitialization(form, action);
      // console.log("ddddd", form, valuesForm);
      handleFormState(true);
      loadData = {
        isAlert: false,
        message: "",
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
    } catch (error) {
      console.error("setCompagnForm error", error);
    }
  };
  const handleDialogForm = (state, action) => {
    if (state) {
      setForm(action);
    } else {
      handleFormState(false);
      valuesFormInitialization(formConfig);
      onSelectedRow({}, "");
    }
  };
  const saveData = async () => {
    const dataForm = [valuesForm];
    // console.log("ddd", dataForm);
    const fetchData = await postData(MODULE_ROBE, dataForm);
    if (fetchData !== ERROR) {
      setTimeout(() => {
        handleDialogForm(false, "");
        getAllData();
      }, 200);
    }
  };
  const getPermission = () => {
    const permissionArray = permissionFetcher(MODULE_DONNEE_REF);
    if (permissionArray.find((i) => i.name.includes(`Consulter${MODULE_DONNEE_REF}`))) {
      const permissionData = [...permissionArray, ...[{ name: `Insert${MODULE_DONNEE_REF}` }]];
      setPermissionModule(permissionData);
      getAllData();
    } else {
      const loadData = {
        isAlert: true,
        message: NO_PERMISSION,
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
      setTimeout(() => {
        navigate(DEFAULT_PATH);
      }, 2000);
    }
  };
  const editData = async () => {
    let dataForm = valuesForm;
    if (actionOption === "qqq") {
      valuesForm.id = selectedItem.id;
      dataForm = [valuesForm];
    }
    dataForm.especeId = valuesForm?.espece?.id ?? "";
    // const fetchData = await putData(MODULE_RACE, dataForm);
    const fetchData = await putData(`robes/${selectedItem.id}`, dataForm);
    if (fetchData !== ERROR) {
      setTimeout(() => {
        handleDialogForm(false, "");
        getAllData();
      }, 200);
    }
  };
  useEffect(() => {
    getPermission();
    localStorage.setItem(LAST_PATH, location.pathname);
  }, []);
  useEffect(() => {
    if (Object.keys(selectedItem).length !== 0) {
      if (actionOption === ACTION_EDIT) {
        handleDialogForm(true, ACTION_EDIT);
      }
    }
  }, [selectedItem]);
  const handleSearch = (value) => {
    if (value === "") {
      renderDataTable(listData);
    } else {
      const searchTerm = normalizeText(value);
      const filteredData = listData.filter((item) => {
        return normalizeText(item?.name).includes(searchTerm);
      });
      if (filteredData.length > 0) {
        renderDataTable(filteredData);
      } else {
        const loadData = {
          isAlert: true,
          message: NO_DATA_FOUND,
          isLoading: false,
          alertSeverity: "error",
        };
        handleLoad(loadData);
      }
    }
  };
  const contextValue = useMemo(() => {
    const value = {
      title: TITLE_ROBE,
      columns,
      rows,
      permissionModule,
      saveData,
      editData,
      module: MODULE_DONNEE_REF,
      isOpenForm,
      handleDialogForm,
      formConfig,
      valuesForm,
      errorForm,
      handleChangeFormValues,
      titleForm,
      validateForm,
      selectedItem,
      actionOption,
      onSelectedRow,
      handleSearch,
      refreshData: getAllData,
    };
    return value;
  }, [
    columns,
    rows,
    permissionModule,
    isOpenForm,
    formConfig,
    valuesForm,
    errorForm,
    selectedItem,
    actionOption,
  ]);

  return (
    <div>
      <LayoutContext.Provider value={contextValue}>
        <DataTableComponent />
      </LayoutContext.Provider>
      <LoadComponent loadData={{ isAlert, isLoading, message, alertSeverity, handleLoad }} />
    </div>
  );
}

export default GestionRobe;
