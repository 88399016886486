import React, { useMemo } from "react";
import BackButton from "components/BackButton";
import servicesAPI from "services/servicesAPI";
import LoadComponent from "components/LoadComponent";
import ModuleTitle from "components/ModuleTitle";
import { useLocation } from "react-router-dom";
import NewDemandeIdentificationComponent from "./component/NewDemandeIdentificationComponent";

function NewDemandeIdentification() {
  const { state } = useLocation();
  const {
    isAlert,
    isLoading,
    message,
    alertSeverity,
    handleLoad,
    getData,
    putData,
    postData,
    getDataUser,
  } = servicesAPI();
  const { selectedData } = state;
  const loadDataConfig = useMemo(() => {
    const value = {
      isAlert,
      isLoading,
      message,
      alertSeverity,
      handleLoad,
    };
    return value;
  }, [isAlert, isLoading, message, alertSeverity]);

  const propsDataConfig = useMemo(() => {
    const value = {
      ApiService: { getData, putData, postData, getDataUser },
      handleLoad,
      selectedData,
    };
    return value;
  }, []);
  const titre = Object.keys(selectedData).length === 0 ? "Nouvelle" : "Détails";
  return (
    <>
      <ModuleTitle title={`${titre} Demande d'Identification`} />
      <BackButton />
      <NewDemandeIdentificationComponent propsDataConfig={propsDataConfig} />
      <LoadComponent loadData={loadDataConfig} />
    </>
  );
}

export default NewDemandeIdentification;
