import { ERROR } from "constant/message";
import { MODULE_ROBE } from "constant/moduleInfo";
import useArrayOperation from "hooks/useArrayOperation";

const { sortArray } = useArrayOperation();

const getRobe = async (getData) => {
  const fetchData = await getData(MODULE_ROBE);
  let result = [];
  if (fetchData !== ERROR && Object.keys(fetchData.content).length !== 0) {
    result = sortArray(fetchData.content, "name");
  }
  return result;
};
export default getRobe;
