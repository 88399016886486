import { Box, Typography } from "@mui/material";
import { TITLE_RACE, MODULE_RACE, MODULE_DONNEE_REF, DEFAULT_PATH } from "constant/moduleInfo";
import React, { useEffect, useMemo, useState } from "react";
import colors from "assets/theme/base/colors";
import servicesAPI from "services/servicesAPI";
import useArrayOperation from "hooks/useArrayOperation";
import LayoutContext from "context/LayoutContext";
import LoadComponent from "components/LoadComponent";
import DataTableComponent from "components/DataTableComponent";
import useTableRaceMenu from "layouts/donneeReference/rubrique/gestionRaceMenu/hooks/useTableRaceMenu";
import useFormRaceMenu from "layouts/donneeReference/rubrique/gestionRaceMenu/hooks/useFormRaceMenu";

import { ERROR, NO_PERMISSION, NO_DATA_FOUND } from "constant/message";
import { ACTION_ADD, ACTION_EDIT } from "constant/dataTableActionConstant";
import permissionFetcher from "utils/permissionFetcher";
import { LAST_PATH } from "constant";
import { useNavigate, useLocation } from "react-router-dom";
import { normalizeText } from "utils/textOperation";

function GestionRaceMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const [permissionModule, setPermissionModule] = useState([]);
  // const userData = userInfos();
  const [formConfig, setFormConfig] = useState([]);
  const [listData, setListData] = useState([]);
  // const [openDialogPermission, setOpenDialogPermission] = useState(false);
  const { isAlert, isLoading, message, alertSeverity, handleLoad, getData, postData, putData } =
    servicesAPI();
  const { columns, rows, renderDataTable, selectedItem, actionOption, onSelectedRow } =
    useTableRaceMenu();
  const {
    isOpenForm,
    handleFormState,
    valuesForm,
    errorForm,
    valuesFormInitialization,
    handleChangeFormValues,
    titleForm,
    validateForm,
    formConfigRace,
  } = useFormRaceMenu();
  const { sortArray, customArrItem } = useArrayOperation();
  const getAllData = async () => {
    const fetchData = await getData(MODULE_RACE);
    // console.log("ddd", userData.roles);
    if (fetchData !== ERROR) {
      const result = sortArray(fetchData, "nomCourant");
      const customArr = customArrItem(result, MODULE_RACE, pathname);

      // console.log("ddd", result);
      setListData(customArr);
      renderDataTable(customArr, MODULE_RACE);
      if (customArr.length === 0) {
        const loadData = {
          isAlert: true,
          message: "Aucune Race Trouvée",
          isLoading: false,
          alertSeverity: "error",
        };
        handleLoad(loadData);
      }
    }
  };
  const setForm = async (action) => {
    try {
      let loadData = {
        isAlert: false,
        message: "",
        isLoading: true,
        alertSeverity: "error",
      };
      handleLoad(loadData);
      let form = [];
      if (action === ACTION_ADD || action === ACTION_EDIT) {
        form = await formConfigRace(selectedItem, getData);
      }
      setFormConfig(form);
      valuesFormInitialization(form, action);
      loadData = {
        isAlert: false,
        message: "",
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
      handleFormState(true);
    } catch (error) {
      console.error("setFermeForm error", error);
    }
  };
  const handleDialogForm = (state, action) => {
    if (state) {
      setForm(action);
    } else {
      handleFormState(false);
      valuesFormInitialization(formConfig);
      onSelectedRow({}, "");
    }
  };
  const saveData = async () => {
    const dataForm = [valuesForm];
    const fetchData = await postData(MODULE_RACE, dataForm);
    if (fetchData !== ERROR) {
      setTimeout(() => {
        handleDialogForm(false, "");
        getAllData();
      }, 200);
    }
  };
  const editData = async () => {
    let dataForm = valuesForm;
    if (actionOption === "qqq") {
      valuesForm.id = selectedItem.id;
      dataForm = [valuesForm];
    }
    dataForm.especeId = valuesForm?.espece?.id ?? "";
    // console.log("dataForm", dataForm);
    // const fetchData = await putData(MODULE_RACE, dataForm);
    const fetchData = await putData(`Races/${selectedItem.id}`, dataForm);
    if (fetchData !== ERROR) {
      setTimeout(() => {
        handleDialogForm(false, "");
        getAllData();
      }, 200);
    }
  };
  const getPermission = () => {
    const permissionArray = permissionFetcher(MODULE_DONNEE_REF);
    if (permissionArray.find((i) => i.name.includes(`Consulter${MODULE_DONNEE_REF}`))) {
      /* const isResponsableFerme = checkRoleExist(RESPONSABLE_FERME);
      let permissionData = permissionArray;
      if (isResponsableFerme) {
        permissionData = [{ name: `Insert${MODULE_DONNEE_REF}` }];
      }
      setPermissionModule(permissionData); */
      const permissionData = [...permissionArray, ...[{ name: `Insert${MODULE_DONNEE_REF}` }]];
      setPermissionModule(permissionData);
      getAllData();
    } else {
      const loadData = {
        isAlert: true,
        message: NO_PERMISSION,
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
      setTimeout(() => {
        navigate(DEFAULT_PATH);
      }, 2000);
    }
  };
  useEffect(() => {
    getPermission();
    localStorage.setItem(LAST_PATH, location.pathname);
  }, []);
  /* const handleCloseDialogPermission = () => {
    setOpenDialogPermission(false);
    onSelectedRow({}, "");
  }; */
  useEffect(() => {
    if (Object.keys(selectedItem).length !== 0) {
      // setOpenDialogPermission(true);
      //  navigate(PATH_RACE, { state: { selectedData: selectedItem } });
      if (actionOption === ACTION_EDIT) {
        handleDialogForm(true, ACTION_EDIT);
      }
    }
  }, [selectedItem]);
  const handleSearch = (value) => {
    if (value === "") {
      renderDataTable(listData, MODULE_RACE);
    } else {
      /* const filteredData = listData.filter(
        (item) =>
          (item.nomCourant !== null &&
            item.nomCourant.toLowerCase().includes(value.toLowerCase())) ||
          (item.nomLatin !== null && item.nomLatin.toLowerCase().includes(value.toLowerCase())) ||
          (item.especeCategory !== null &&
            item.especeCategory.toLowerCase().includes(value.toLowerCase())) ||
          (item.codeEspece !== null && item.codeEspece.toLowerCase().includes(value.toLowerCase()))
      ); */
      const searchTerm = normalizeText(value);
      const filteredData = listData.filter((item) => {
        return (
          normalizeText(item?.nomCourant).includes(searchTerm) ||
          normalizeText(item?.codeRace).includes(searchTerm) ||
          normalizeText(item?.espece?.nomCourant).includes(searchTerm)
        );
      });
      if (filteredData.length > 0) {
        renderDataTable(filteredData);
      } else {
        const loadData = {
          isAlert: true,
          message: NO_DATA_FOUND,
          isLoading: false,
          alertSeverity: "error",
        };
        handleLoad(loadData);
      }
      // renderDataTable(filteredData, MODULE_RACE);
      // console.log("ddd", value, filteredData.length);
    }
  };
  const contextValue = useMemo(() => {
    const value = {
      columns,
      rows,
      permissionModule,
      saveData,
      editData,
      module: MODULE_DONNEE_REF,
      isOpenForm,
      handleDialogForm,
      formConfig,
      valuesForm,
      errorForm,
      handleChangeFormValues,
      titleForm,
      validateForm,
      selectedItem,
      actionOption,
      onSelectedRow,
      handleSearch,
      refreshData: getAllData,
    };
    return value;
  }, [
    columns,
    rows,
    permissionModule,
    isOpenForm,
    formConfig,
    valuesForm,
    errorForm,
    selectedItem,
    actionOption,
  ]);
  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography
          display="block"
          variant="h4"
          fontWeight="medium"
          style={{ color: colors.success.main }}
        >
          {TITLE_RACE}
        </Typography>
      </Box>
      <LayoutContext.Provider value={contextValue}>
        <DataTableComponent />
      </LayoutContext.Provider>
      {/* openDialogPermission && (
        <GestionRace
          open={openDialogPermission}
          handleClose={handleCloseDialogPermission}
          selectedItem={selectedItem}
          refreshData={getAllData}
        />
      ) */}
      <LoadComponent loadData={{ isAlert, isLoading, message, alertSeverity, handleLoad }} />
    </div>
  );
}

export default GestionRaceMenu;
