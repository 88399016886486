export const MODULE_USER = "Utilisateurs";
export const PATH_USER = "/utilisateurs";
export const TITLE_USER = "Liste des Utilisateurs";
export const ENDPOINT_GET_USER = "Users";

export const PATH_USER_N1 = "/utilisateurs/administrateurs/nationaux";
export const KEY_USER_N1 = "utilisateurs/administrateurs/nationaux";
export const TITLE_USER_N1 = "Liste des Administrateurs Nationaux";

export const PATH_USER_N2 = "/utilisateurs/administrateurs/regionaux";
export const KEY_USER_N2 = "utilisateurs/administrateurs/regionaux";
export const TITLE_USER_N2 = "Liste des Administrateurs Régionaux";

export const PATH_USER_N3 = "/utilisateurs/administrateurs/departementaux";
export const KEY_USER_N3 = "utilisateurs/administrateurs/departementaux";
export const TITLE_USER_N3 = "Liste des Administrateurs Départementaux";

export const PATH_USER_N4I = "/utilisateurs/identificateurs";
export const KEY_USER_N4I = "utilisateurs/identificateurs";
export const TITLE_USER_N4I = "Liste des Agents Identificateurs";

export const PATH_USER_N4INS = "/utilisateurs/inseminateurs";
export const KEY_USER_N4INS = "utilisateurs/inseminateurs";
export const TITLE_USER_N4INS = "Liste des Inseminateurs";

export const PATH_USER_N4R = "/utilisateurs/responsables_exploitation";
export const KEY_USER_N4R = "utilisateurs/responsables_exploitation";
export const TITLE_USER_N4R = "Liste des Responsables d'exploitation";

export const PATH_USER_N5E = "/utilisateurs/eleveurs";
export const KEY_USER_N5E = "utilisateurs/eleveurs";
export const TITLE_USER_N5E = "Liste des Eleveurs";

export const PATH_USER_N5P = "/utilisateurs/proprietaires";
export const KEY_USER_N5P = "utilisateurs/proprietaires";
export const TITLE_USER_N5P = "Liste des Propriétaire";

export const PATH_DETAIL_USER = "/utilisateurs/details";
export const KEY_DETAIL_USER = "utilisateurs/details";
export const TITLE_DETAIL_USER = "Détails de l'utilisateur";

export const PATH_SEARCH_USER = "/utilisateurs/search";
export const KEY_SEARCH_USER = "utilisateurs/search";
export const TITLE_SEARCH_USER = "Recherche";

export const MODULE_FERME = "Fermes";

export const PATH_FERME = "/donnees_ref/exploitations_publiques";
export const KEY_FERME = "donnees_ref/exploitations_publiques";
export const TITLE_FERME = "Liste des Exploitations Publiques";

export const PATH_LOCAUX = "/donnees_ref/exploitation/locaux";
export const KEY_LOCAUX = "donnees_ref/exploitation/locaux";
export const TITLE_LOCAUX = "Liste des Locaux";

export const MODULE_EXPLOITATION = "Exploitations";
export const PATH_EXPLOITATION_PUBLIC = "/donnees_ref/exploitations_publiques";
export const KEY_EXPLOITATION_PUBLIC = "donnees_ref/exploitations_publiques";
export const TITLE_EXPLOITATION_PUBLIC = "Liste des Exploitations Publiques";

export const PATH_EXPLOITATION_PRIVEE = "/donnees_ref/exploitations_privees";
export const KEY_EXPLOITATION_PRIVEE = "donnees_ref/exploitations_privees";
export const TITLE_EXPLOITATION_PRIVEE = "Liste des Exploitations Privées";

export const MODULE_CAMPAGNE = "Campagnes";
export const PATH_CAMPAGNE = "/donnees_ref/operations";
export const KEY_CAMPAGNE = "donnees_ref/operations";
export const TITLE_CAMPAGNE = "Listes des operations";

export const MODULE_LOT = "Lots";
// export const PATH_LOT = "/reproduction/lots";
export const PATH_LOT = "/donnees_ref/exploitations/lots";
export const TITLE_LOT = "Liste des Lots";

export const MODULE_REPRODUCTION = "Reproduction";
export const PATH_REPRODUCTION = "/reproduction=";
export const TITLE_REPRODUCTION = "Reproduction";

export const MODULE_CONSULTATION = "Consultations";
export const PATH_CONSULTATION = "/reproduction/consultations";
export const TITLE_CONSULTATION = "Liste des Consultations";
// export const ENDPOINT_GET_CONSULTATION = `${MODULE_CONSULTATION}?typeConsultation=DIAGNOSTIC`;
export const ENDPOINT_GET_CONSULTATION = `${MODULE_CONSULTATION}?typeConsultation=`;
export const ENDPOINT_POSITIVE_DIAGNOSTIC_FEMALE_ANIMAL = "Consultations/animals/female/positive";
export const ENDPOINT_POSITIVE_DIAGNOSTIC_FEMALE_ANIMAL2 = "Consultations/animals/female/positive";
export const PATH_DETAIL_CONSULTATION = "/reproduction/consultations/details";
export const KEY_DETAIL_CONSULTATION = "reproduction/consultations/details";
export const TITLE_DETAIL_CONSULTATION = "Détails de l'utilisateur";

export const MODULE_SEMENCE = "Semences";
export const PATH_SEMENCE = "/reproduction/semences";
export const TITLE_SEMENCE = "Liste des Semences";

export const MODULE_INSEMINATION = "Insemination";
export const PATH_INSEMINATION = "/reproduction/inseminations";
export const TITLE_INSEMINATION = "Liste des Inseminations";

export const PATH_DETAIL_INSEMINATION = "/reproduction/inseminations/details";
export const KEY_DETAIL_INSEMINATION = "reproduction/inseminations/details";
export const TITLE_DETAIL_INSEMINATION = "Détails de l'utilisateur";

export const MODULE_MISE_BAS = "mise-bas";
export const PATH_MISE_BAS = "/reproduction/mise-bas";
export const TITLE_MISE_BAS = "Liste des Mises Bas";

export const PATH_DETAIL_MISE_BAS = "/reproduction/mise-bas/details";
export const KEY_DETAIL_MISE_BAS = "reproduction/mise-bas/details";
export const TITLE_DETAIL_MISE_BAS = "Détails de l'utilisateur";

export const MODULE_PRODUCT = "product-insemination";
export const PATH_PRODUCT = "/reproduction/produit";
export const TITLE_PRODUCT = "Liste des Produits";

export const PATH_DETAIL_PRODUCT = "/reproduction/produit/details";
export const KEY_DETAIL_PRODUCT = "reproduction/produit/details";
export const TITLE_DETAIL_PRODUCT = "Détails de l'utilisateur";

export const PATH_LOGOUT = "/logout";

export const MODULE_LIEU = "Lieu";
export const PATH_LIEU = "/donnees_ref/lieu";
export const KEY_LIEU = "donnees_ref/lieu";
export const TITLE_LIEU = "Liste des Lieux";

export const MODULE_ANIMAL = "animals";

export const MODULE_DONNEE_REF = "DonneesReferences";
export const PATH_DONNEE_REF = "/donnees_ref";
export const TITLE_DONNEE_REF = "Données de Référence";

export const MODULE_ESPECE = "especes";
export const PATH_ESPECE = "/donnees_ref/espece";
export const TITLE_ESPECE = "Liste des Especes";

export const MODULE_ROLE = "Roles";
// export const PATH_ROLE = "/donnees_ref/role";
export const PATH_ROLE = "/donnees_ref/fonctions";
export const TITLE_ROLE = "Liste des Fonctions";

export const MODULE_PROFIL = "Profils";
export const KEY_PROFIL = "donnees_ref/profils";
export const PATH_PROFIL = "/donnees_ref/profils";
export const TITLE_PROFIL = "Liste des Profils";

export const MODULE_RACE = "Races";
export const PATH_RACE = "/donnees_ref/espece/race";
export const TITLE_RACE = "Liste des Races";
export const PATH_RACE_MENU = "/donnees_ref/race";

/* export const MODULE_MALADIE = "Maladies";
export const PATH_MALADIE = "/donnees_ref/maladie";
export const TITLE_MALADIE = "Liste des Maladies";

export const MODULE_VACCIN = "Vaccins";
export const PATH_VACCIN = "/donnees_ref/vaccins";
export const TITLE_VACCIN = "Liste des Vaccins"; */

export const MODULE_PERMISSION = "Permissions";
export const PATH_PERMISSION = "/donnees_ref/permission";
export const TITLE_PERMISSION = "Liste des Permissions";

export const MODULE_IDENTIFICATION = "Identification";
// export const PATH_IDENTIFICATION = "/Identification";
// export const KEY_IDENTIFICATION = "/Identification";
// export const TITLE_IDENTIFICATION = "Identification des Animaux";

export const MODULE_DASHBOARD = "Dashboard";
export const PATH_DASHBOARD = "/dashboard";
export const KEY_DASHBOARD = "dashboard";
export const TITLE_DASHBOARD = "Dashboard";

export const MODULE_DASHBOARD_ELEVAGE = "Animaux d'élevage";
export const PATH_DASHBOARD_ELEVAGE = "/dashboard/elevage";
export const KEY_DASHBOARD_ELEVAGE = "dashboard/elevage";
export const TITLE_DASHBOARD_ELEVAGE = "Animaux d'élevage";
export const TITLE_DASHBOARD_ELEVAGE_CIV = "Animaux d'élevage";

export const MODULE_DASHBOARD_COMPAGNIE = "Animaux de Compagnie";
export const PATH_DASHBOARD_COMPAGNIE = "/dashboard/compagnie";
export const TITLE_DASHBOARD_COMPAGNIE = "Animaux de compagnie";

export const MODULE_DASHBOARD_TRANSHUMANCE = "Animaux Transhumants";
export const PATH_DASHBOARD_TRANSHUMANCE = "/dashboard/transhumance";
export const TITLE_DASHBOARD_TRANSHUMANCE = "Animaux Transhumants";

export const DEFAULT_PATH = PATH_DASHBOARD;

export const ENDPOINT_DASHBOARD_N1 =
  "animals/byDivisionLevel/codeLieu?division=NIVEAU_PAYS&codeLieu=";
export const ENDPOINT_DASHBOARD_N2 =
  "animals/byDivisionLevel/codeLieu?division=NIVEAU_REGION&codeLieu=";
export const ENDPOINT_DASHBOARD_N3 =
  "animals/byDivisionLevel/codeLieu?division=NIVEAU_DEPARTEMENT&codeLieu=";

export const ENDPOINT_DASHBOARD_FERME_N1 =
  "animals/byDivisionLevel/codeLieu/ferme?division=NIVEAU_PAYS&codeLieu=";
export const ENDPOINT_DASHBOARD_FERME_N2 =
  "animals/byDivisionLevel/codeLieu/ferme?division=NIVEAU_REGION&codeLieu=";
export const ENDPOINT_DASHBOARD_FERME_N3 =
  "animals/byDivisionLevel/codeLieu/ferme?division=NIVEAU_DEPARTEMENT&codeLieu=";

export const MODULE_DASHBOARD_REPRODUCTION = "Animaux d'élevage";
export const PATH_DASHBOARD_REPRODUCTION = "/dashboard/insemination";
export const KEY_DASHBOARD_REPRODUCTION = "dashboard/insemination";
export const TITLE_DASHBOARD_REPRODUCTION = "Dashboard  Insemination";

export const ENDPOINT_DASHBOARD_REPRODUCTION_N1 =
  "Insemination/byDivisionLevel/codeLieu?division=NIVEAU_PAYS&codeLieu=";
export const ENDPOINT_DASHBOARD_REPRODUCTION_N2 =
  "Insemination/byDivisionLevel/codeLieu?division=NIVEAU_REGION&codeLieu=";
export const ENDPOINT_DASHBOARD_REPRODUCTION_N3 =
  "Insemination/byDivisionLevel/codeLieu?division=NIVEAU_DEPARTEMENT&codeLieu=";

export const MODULE_ENTITE = "Entite";
export const MODULE_ENTITE_TYPE = "Entite/type";

export const PATH_ENTITE_DETAIL = "/donnees_ref/entite/details";

export const PATH_DIRECTION = "/donnees_ref/directions";
export const KEY_DIRECTION = "donnees_ref/directions";
export const TITLE_DIRECTION = "Liste des Directions";

export const PATH_STRUCTURE_PRIVEE = "/donnees_ref/structures_privees";
export const KEY_STRUCTURE_PRIVEE = "donnees_ref/structures_privees";
export const TITLE_STRUCTURE_PRIVEE = "Liste des Structures Privées";

export const MODULE_TAG = "tags";
export const PATH_TAG = "/donnees_ref/projets";
export const KEY_TAG = "donnees_ref/projets";
export const TITLE_TAG = "Liste des Projets";

export const MODULE_ROBE = "robes";
export const PATH_ROBE = "/donnees_ref/robes";
export const KEY_ROBE = "donnees_ref/robes";
export const TITLE_ROBE = "Liste des robes";

export const MODULE_ANIMAUX = "animals";
export const PATH_ANIMAUX = "/donnees_ref/exploitations/animaux";
export const KEY_ANIMAUX = "donnees_ref/directions";
export const TITLE_ANIMAUX = "Liste des Animaux";

export const PATH_DETAIL_ANIMAL = "/animal/details";
export const TITLE_DETAIL_ANIMAL = "Détails de l'animal";

export const MODULE_DEMANDE_IDENTIFICATION = "demandes";
export const PATH_DEMANDE_IDENTIFICATION = "/animal/identification/demande";
export const KEY_DEMANDE_IDENTIFICATION = "animal/identification/demande";
export const TITLE_DEMANDE_IDENTIFICATION = "Liste des Demandes d'Identification";

export const PATH_DETAIL_DEMANDE_IDENTIFICATION = "/animal/identification/demande/details";

export const PATH_NEW_DEMANDE_IDENTIFICATION = "/animal/identification/demande/new";

// export const MODULE_IDENTIFICATION = "demande";
export const PATH_IDENTIFICATION = "/animal/identification";
export const KEY_IDENTIFICATION = "animal/identification";
export const TITLE_IDENTIFICATION = "Liste des Animaux Identifiés";

export const PATH_MPSA = "/malette_pedagogique_sante_animale";
export const KEY_MPSA = "malette_pedagogique_sante_animale";
export const TITLE_MPSA = "Malette Pédagogique Santé Animale";

export const MODULE_MALADIE = "Maladie";
export const PATH_MALADIE = "/malette_pedagogique_sante_animale/maladies";
export const TITLE_MALADIE = "Maladies Animales Prioritaires";

export const MODULE_MALADIE_ZOONOTIQUE = "Maladie_Zonotique";
export const PATH_MALADIE_ZOONOTIQUE = "/malette_pedagogique_sante_animale/maladies-zoonotique";
export const TITLE_MALADIE_ZOONOTIQUE = "Maladies zoonotiques soumises  à déclaration obligatoire";
export const ENDPOINT_GET_MALADIE_ZOONOTIQUE = "Maladie/byType?maladieType=Zonotique";

export const MODULE_MALADIE_NON_ZOONOTIQUE = "Maladie_Non_Zonotique";
// export const MODULE_MALADIE_NON_ZOONOTIQUE = "maladie";
export const PATH_MALADIE_NON_ZOONOTIQUE =
  "/malette_pedagogique_sante_animale/maladies-non-zoonotique";
export const TITLE_MALADIE_NON_ZOONOTIQUE =
  "Maladies non zoonotiques soumises  à déclaration obligatoire";
export const ENDPOINT_GET_MALADIE_NON_ZOONOTIQUE = "Maladie/byType?maladieType=NonZonotique";

export const MODULE_MALADIE_NON_OBLIGATOIRE = "Maladie_Non_Soumise_Declaration";
export const PATH_MALADIE_NON_OBLIGATOIRE =
  "/malette_pedagogique_sante_animale/maladies-non-obligatoire";
export const TITLE_MALADIE_NON_OBLIGATOIRE = "Maladies non  soumises  à déclaration obligatoire";
export const ENDPOINT_GET_MALADIE_NON_OBLIGATOIRE = "Maladie/byType?maladieType=NonZonotique";

export const MODULE_TECHNIQUE = "TECHNICSBASES";
export const PATH_TECHNIQUE = "/malette_pedagogique_sante_animale/techniques";
export const TITLE_TECHNIQUE = "Techniques de base en santé animale";
export const ENDPOINT_GET_TECHNIQUE = "technics-base";

export const MODULE_EXAMEN = "EXAMEN";
export const PATH_EXAMEN = "/malette_pedagogique_sante_animale/examen-clinique";
export const TITLE_EXAMEN = "Examen Clinique";
export const ENDPOINT_GET_EXAMEN = "technics-base";

export const MODULE_DIAGNOSTIC = "Diagnostic";
export const PATH_DIAGNOSTIC = "/malette_pedagogique_sante_animale/diagnostic";
export const TITLE_DIAGNOSTIC = "Diagnostics Différentiels";
export const ENDPOINT_GET_DIAGNOSTIC = "Diagnostic";
export const ENDPOINT_GET_DIAGNOSTIC_MALADIE = "diagnostic/maladies?diagnosticName=";

export const MODULE_TRAITEMENT = "Traitement";
export const PATH_TRAITEMENT = "/malette_pedagogique_sante_animale/traitements";
export const TITLE_TRAITEMENT = "Principaux Traitements";

export const MODULE_MEDICAMENT = "MEDICAMENT";
export const PATH_MEDICAMENT = `/malette_pedagogique_sante_animale/${MODULE_TRAITEMENT}/medicaments`;
export const TITLE_MEDICAMENT = "Medicaments";
export const ENDPOINT_GET_MEDICAMENT = "Medicament/get-all-medicaments";

export const MODULE_VACCIN = "VACCINS";
export const PATH_VACCIN = "/malette_pedagogique_sante_animale/vaccins";
export const TITLE_VACCIN = "Vaccins";
export const ENDPOINT_GET_VACCIN = "Vaccin/get-all-vaccins";

export const MODULE_PRELEVEMENT = "PRELEVEMENT";
export const PATH_PRELEVEMENT = "/malette_pedagogique_sante_animale/prelevements";
export const TITLE_PRELEVEMENT = "Prélèvements";
export const ENDPOINT_GET_PRELEVEMENT = "Vaccin/get-all-vaccins";

export const MODULE_TECHNIQUE_VETERINAIRE = "AUTOPSIE";
export const PATH_TECHNIQUE_VETERINAIRE =
  "/malette_pedagogique_sante_animale/techniques_veterinaires";
export const TITLE_TECHNIQUE_VETERINAIRE = "Techniques Vétérinaires";
export const ENDPOINT_TECHNIQUE_VETERINAIRE = "Vaccin/get-all-vaccins";

export const MODULE_AUTEUR = "TRAITEMENT";
export const PATH_AUTEUR = "/malette_pedagogique_sante_animale/copyrights";
export const TITLE_AUTEUR = "Auteurs rédacteurs copyrights";
export const ENDPOINT_AUTEUR = "Vaccin/get-all-vaccins";

export const MODULE_ANNEXE = "ANNEXE";
export const PATH_ANNEXE = "/malette_pedagogique_sante_animale/annexes";
export const TITLE_ANNEXE = "Annexes";
export const ENDPOINT_GET_ANNEXE = "annex";
export const ENDPOINT_UPLOAD_ANNEXE = "file/attachBy-BusinessType";
