import useArrayOperation from "hooks/useArrayOperation";
import { ERROR, NO_DATA_FOUND } from "constant/message";
import {
  MODULE_USER,
  PATH_USER_N3,
  PATH_USER_N4I,
  PATH_USER_N4INS,
  PATH_USER_N4R,
  PATH_USER_N5E,
  PATH_USER_N5P,
} from "constant/moduleInfo";
import { normalizeText } from "utils/textOperation";
import { CurrentUserLieu, CurrentUserNiveau } from "utils/userInfos";

const { customArrItem, sortArray } = useArrayOperation();

const getUserbyRole = async (getDataUser, listTargetRole) => {
  const dataByRole = await Promise.all(
    listTargetRole.map(async (i) => {
      const arrayData = await getDataUser(`Users/${i.role}`);
      return arrayData;
    })
  );
  const fetchData = dataByRole.flat();
  let dataUser = [];
  if (fetchData !== ERROR && fetchData?.some((i) => i !== ERROR)) {
    if (Array.isArray(fetchData) && fetchData.length > 0) {
      const customArr = customArrItem(fetchData, MODULE_USER);
      dataUser = sortArray(customArr, "userName");
    }
  }
  return dataUser;
};
export default getUserbyRole;
export const searchUser = async (postData, numTel) => {
  const searchValue = [
    {
      field: "numTel",
      searchTerm: numTel,
      queryType: "Text",
    },
  ];
  const fetchData = await postData("search/index/users", searchValue);
  let result = [];
  if (fetchData !== ERROR) {
    result = fetchData;
  }
  return result;
};
export const getUserEntity = async (getData, entiteName) => {
  const fetchData = await getData(`Users/entite/${entiteName}`);

  let result = [];
  // console.log("dddddd", fetchData);
  if (fetchData !== ERROR) {
    const dataUser = fetchData.map((i) => {
      const item = {
        ...i,
        ...{ userName: `${i.prenom} ${i.nom}` },
      };
      return item;
    });
    result = sortArray(dataUser, "name");
  }
  return result;
};
export const handleSearchUser = (listUser, handleLoad, value) => {
  let result;
  if (value === "") {
    result = listUser;
  }
  const searchTerm = normalizeText(value);

  const filteredData = listUser.filter((item) => {
    return (
      normalizeText(item?.prenom).includes(searchTerm) ||
      normalizeText(item?.nom).includes(searchTerm) ||
      normalizeText(item?.numTel).includes(searchTerm) ||
      normalizeText(item?.lieu?.region).includes(searchTerm) ||
      normalizeText(item?.lieu?.departement).includes(searchTerm) ||
      normalizeText(item?.lieu?.commune).includes(searchTerm) ||
      normalizeText(item?.fonction).includes(searchTerm) ||
      item?.roles?.some((i) => normalizeText(i.roleName).includes(searchTerm))
    );
  });
  if (filteredData.length > 0) {
    result = filteredData;
  }
  const loadData = {
    isAlert: true,
    message: NO_DATA_FOUND,
    isLoading: false,
    alertSeverity: "error",
  };
  handleLoad(loadData);
  return result;
};
export const filterDataUserByLevel = async (arrayUser, pathName) => {
  const lieuUser = await CurrentUserLieu();
  let result = arrayUser; /* .map((i) => ({ ...i, label: `${i.prenom} ${i.nom}` })); */
  const UserNiveau = CurrentUserNiveau();
  // console.log("sss", UserNiveau);
  if (
    UserNiveau === 2 &&
    (pathName === PATH_USER_N3 ||
      pathName === PATH_USER_N4I ||
      pathName === PATH_USER_N4INS ||
      pathName === PATH_USER_N4R ||
      pathName === PATH_USER_N5E ||
      pathName === PATH_USER_N5P)
  ) {
    result = result.filter((i) => i.lieu.region === lieuUser?.region);
  } else if (UserNiveau === 3 || UserNiveau === 4) {
    if (pathName === PATH_USER_N3) {
      result = result.filter((i) => i.lieu.region === lieuUser?.region);
    } else if (
      pathName === PATH_USER_N4I ||
      pathName === PATH_USER_N4INS ||
      pathName === PATH_USER_N4R ||
      pathName === PATH_USER_N5E ||
      pathName === PATH_USER_N5P
    ) {
      // console.log("qqq 99", lieuUser?.departement);
      result = result.filter((i) => i.lieu.departement === lieuUser?.departement);
    }
  }
  return result;
};
