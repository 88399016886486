import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  // FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  // OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import {
  INPUT_COMPONENT,
  AUTO_COMPLETE_COMPONENT,
  DATE_COMPONENT,
  MULTI_SELECT_COMPONENT,
  SWITCH_COMPONENT,
  PHONE_INPUT_COMPONENT,
} from "constant/formComponentName";
import LayoutContext from "context/LayoutContext";
import MDInput from "components/MDInput";
import { SUCCESS } from "constant/message";

import DatePickerComponent from "components/DatePicker";
import PhoneInput from "react-phone-number-input/input";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// import { MODULE_CAMPAGNE } from "constant/moduleInfo";
import {
  ACTION_ADD,
  ACTION_EDIT,
  ACTION_ROLE,
  ACTION_DETAIL,
} from "constant/dataTableActionConstant";
import MDTypography from "components/MDTypography";

function formComponent() {
  const layoutContext = useContext(LayoutContext);
  const {
    formConfig,
    isOpenForm,
    handleDialogForm,
    valuesForm,
    errorForm,
    titleForm,
    handleChangeFormValues,
    validateForm,
    saveData,
    editData,
    module,
    actionOption,
  } = layoutContext;
  // console.log("valuesForm", formConfig, isOpenForm, titleForm);
  // console.log("valuesForm", valuesForm);
  // console.log("errorForm", errorForm);
  // console.log("errorForm", actionOption);
  const onCloseDialog = () => {
    handleDialogForm(false);
    // console.log("close");
  };
  const onSubmit = () => {
    const result = validateForm(formConfig, module);
    if (result === SUCCESS) {
      if (
        actionOption === ACTION_ADD ||
        actionOption === ACTION_ROLE ||
        actionOption === ACTION_DETAIL
      ) {
        saveData();
      } else if (actionOption === ACTION_EDIT) {
        editData();
      }
    }
    // console.log("onSubmit", result);
  };
  const switchTheme = (status) =>
    createTheme({
      components: {
        MuiSwitch: {
          styleOverrides: {
            switchBase: {
              color: `${status ? "green" : "red"}`,
            },
            colorPrimary: {
              "&.Mui-checked": {
                // color: item.etatColor,
                color: "green",
              },
            },
            track: {
              opacity: 0.5,
              ".Mui-checked.Mui-checked + &": {
                opacity: 0.7,
                backgroundColor: `${status ? "green" : "red"}`,
              },
            },
          },
        },
      },
    });
  return (
    <Dialog
      open={isOpenForm}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {titleForm}
        {/* module === MODULE_ANIMAL ? "" : module */}
      </DialogTitle>
      <DialogContent style={{ height: "500px", paddingBottom: 150 }}>
        <Card>
          <Box pt={4} pb={3} px={3}>
            <Box component="form" role="form">
              {formConfig?.map((i) => (
                <Box mb={2}>
                  {i.isVisible && (
                    <>
                      {i.component === INPUT_COMPONENT && (
                        <MDInput
                          type="text"
                          label={i.label}
                          name={i.name}
                          fullWidth
                          onChange={(event) => handleChangeFormValues(event, module)}
                          // onChange={handleChangeFormValues}
                          error={!!errorForm[i.name]}
                          helperText={errorForm[i.name] && errorForm[i.name]}
                          // required
                          defaultValue={valuesForm[i.name] ? valuesForm[i.name] : ""}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              onSubmit();
                            }
                          }}
                          disabled={i.isdisabled}
                        />
                      )}
                      {i.component === AUTO_COMPLETE_COMPONENT && (
                        <div>
                          {i.list?.length > 0 ? (
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={i.list}
                              name={i.name}
                              value={valuesForm[i.name] ? valuesForm[i.name] : ""}
                              noOptionsText="Aucune donnée disponible"
                              // onChange={handleChangeFormValues}
                              onChange={(event, newValue, reason) => {
                                // console.log("ddd", reason, newValue);
                                const data = {
                                  target: {
                                    name: i.name,
                                    value: newValue,
                                  },
                                };
                                if (reason === "clear") {
                                  data.target.value = "";
                                }
                                /* const data = {
                            target: {
                            name: i.name,
                          value: newValue,
                                  },
                                }; */
                                handleChangeFormValues(data, formConfig);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={i.label}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      onSubmit();
                                    }
                                  }}
                                />
                              )}
                              disabled={i.isdisabled}
                            />
                          ) : (
                            <p>Aucune liste de {i.name} fournie</p>
                          )}
                          {errorForm[i.name] && (
                            <FormHelperText error>{errorForm[i.name]}</FormHelperText>
                          )}
                        </div>
                      )}
                      {i.component === DATE_COMPONENT && (
                        <DatePickerComponent
                          label={i.label}
                          name={i.name}
                          module={module}
                          dateValue={valuesForm[i.name] ? valuesForm[i.name] : new Date()}
                          handleDate={handleChangeFormValues}
                          isError={errorForm[i.name] && errorForm[i.name]}
                          error={errorForm[i.name] && errorForm[i.name]}
                          disabled={i.isdisabled}
                        />
                      )}
                      {i.component === MULTI_SELECT_COMPONENT && (
                        <Box>
                          {i.list?.length > 0 ? (
                            <FormControl sx={{ m: 1, minWidth: "100%" }} disabled={i.isdisabled}>
                              <InputLabel id="multiple-select-label">{i.label}</InputLabel>
                              <Select
                                multiple
                                value={valuesForm[i.name] ? valuesForm[i.name] : []}
                                onChange={(event) => handleChangeFormValues(event, module)}
                                name={i.name}
                                input={<Input />}
                                // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => (
                                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {selected.map((value) => (
                                      <Chip
                                        key={value.id}
                                        label={value.label}
                                        style={{ margin: 2 }}
                                      />
                                    ))}
                                  </div>
                                )}
                                sx={{
                                  "& .MuiSelect-select": {
                                    minWidth: "200px", // Adjust width of the select input
                                    paddingRight: "30px", // Add padding to accommodate the dropdown arrow
                                    marginBottom: "5px",
                                  },
                                }}
                              >
                                {i.list?.map((x) => (
                                  <MenuItem key={x.id} value={x}>
                                    {x.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errorForm[i.name] && (
                                <FormHelperText error>{errorForm[i.name]}</FormHelperText>
                              )}
                            </FormControl>
                          ) : (
                            <p>Aucune liste de {i.name} fournie</p>
                          )}
                        </Box>
                      )}
                      {/* i.component === SWITCH_COMPONENT && (
                        <FormControlLabel
                          label={i.label}
                          // labelPlacement="top"
                          disabled={i.isdisabled}
                          labelPlacement="start"
                          control={
                            <Switch
                              checked={valuesForm[i.name] ? valuesForm[i.name] : false}
                              onChange={(event) => {
                                const data = {
                                  target: {
                                    name: i.name,
                                    value: event.target.checked,
                                  },
                                };
                                handleChangeFormValues(data, module);
                              }}
                            />
                          }
                        />
                      ) */}
                      {i.component === SWITCH_COMPONENT && (
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <MDTypography display="block" variant="button" fontWeight="regular">
                              {i.label}
                            </MDTypography>
                            {/* Label before the switch */}
                          </Grid>
                          <Grid item>
                            <ThemeProvider
                              theme={() =>
                                switchTheme(valuesForm[i.name] ? valuesForm[i.name] : false)
                              }
                            >
                              <Switch
                                checked={valuesForm[i.name] ? valuesForm[i.name] : false}
                                disabled={i.isdisabled}
                                onChange={(event) => {
                                  const data = {
                                    target: {
                                      name: i.name,
                                      value: event.target.checked,
                                    },
                                  };
                                  handleChangeFormValues(data, module);
                                }}
                                sx={{
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                    color: "#4caf50", // Green thumb color when checked
                                  },
                                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                    backgroundColor: "#4caf50", // Green track color when checked
                                  },
                                }}
                              />
                            </ThemeProvider>
                          </Grid>
                        </Grid>
                      )}

                      {i.component === PHONE_INPUT_COMPONENT && (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          // required
                          fullWidth
                          id="telephone"
                          label={i.label}
                          name={i.name}
                          autoComplete="telephone"
                          error={!!errorForm[i.name]}
                          helperText={errorForm[i.name] && errorForm[i.name]}
                          InputProps={{
                            inputComponent: PhoneInput,
                            inputProps: {
                              value: `${valuesForm[i.name] ? valuesForm[i.name] : ""}`,
                              onChange: (event) => {
                                const data = {
                                  target: {
                                    name: i.name,
                                    value: event,
                                  },
                                };
                                handleChangeFormValues(data, module);
                              },
                              placeholder: "Saisir le numéro de téléphone",
                              defaultCountry: "MR",
                              maxLength: 13,
                            },
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              onSubmit();
                            }
                          }}
                          disabled={i.isdisabled}
                        />
                      )}
                    </>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog}>Annuler</Button>
        <Button onClick={onSubmit} autoFocus>
          {/* titleForm === "Ajout" ? "Enregistrer" : "Modifier" */}
          {actionOption === ACTION_ADD
            ? "Enregistrer"
            : (actionOption === ACTION_EDIT || actionOption === ACTION_ROLE) && "Modifier"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

formComponent.propTypes = {
  formConfig: PropTypes.object,
  open: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  valuesForm: PropTypes.object,
  errorForm: PropTypes.object,
  handleChangeFormValues: PropTypes.func,
};
formComponent.defaultProps = {
  formConfig: [],
  open: false,
  title: "",
  // eslint-disable-next-line prettier/prettier
  handleClose: () => { },
  valuesForm: {},
  errorForm: {},
  // eslint-disable-next-line prettier/prettier
  handleChangeFormValues: () => { },
};
// Memoized component
const Form = React.memo(formComponent);
export default Form;
