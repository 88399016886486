import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import getMouvement from "services/mouvementService";
import DataTable from "examples/Tables/DataTable";
import ModuleTitle from "components/ModuleTitle";
import { normalizeText } from "utils/textOperation";
import { NO_DATA_FOUND } from "constant/message";

const AnimalHistory = ({ ApiService, idAnimal }) => {
  const [listHistorique, setListHistorique] = useState([]);
  const [rows, setRows] = useState([]);
  const handleSearch = (value) => {
    if (value === "") {
      setRows(listHistorique);
      // setlistFilterData(listData);
    } else {
      const searchTerm = normalizeText(value);

      const filteredData = listHistorique.filter((item) => {
        return (
          normalizeText(item?.dateMouvement).includes(searchTerm) ||
          normalizeText(item?.motif).includes(searchTerm) ||
          normalizeText(item?.previousValue).includes(searchTerm) ||
          normalizeText(item?.currentValue).includes(searchTerm)
        );
      });
      if (filteredData.length > 0) {
        setRows(filteredData);
      } else {
        const loadData = {
          isAlert: true,
          message: NO_DATA_FOUND,
          isLoading: false,
          alertSeverity: "error",
        };
        ApiService.handleLoad(loadData);
      }
    }
  };
  const fetchHistoryData = async () => {
    const fetchData = await getMouvement(ApiService, idAnimal);
    setListHistorique(fetchData);
    setRows(fetchData);
  };
  useEffect(async () => {
    fetchHistoryData();
  }, []);
  const columns = [
    { Header: "date", accessor: "dateMouvement", align: "center" },
    { Header: "motif", accessor: "motif", align: "center" },
    { Header: "info précedente", accessor: "previousValue", align: "center" },
    { Header: "Mise à jour", accessor: "currentValue", align: "center" },
  ];
  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <ModuleTitle title="Historique de l'animal" />
      <DataTable
        table={{ columns, rows }}
        isSorted={false}
        entriesPerPage
        showTotalEntries
        noEndBorder
        canSearch
        handleSearch={handleSearch}
        refreshData={fetchHistoryData}
      />
    </Box>
  );
};

export default AnimalHistory;
AnimalHistory.propTypes = {
  idAnimal: PropTypes.number.isRequired,
  ApiService: PropTypes.func.isRequired,
};
