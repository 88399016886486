// const apiUrl = "http://vps-19596d03.vps.ovh.net:19999/api/v1";
// const apiUrl = "https://mpsa.oi.betaclic.com:19998/api/v1";
// Back end Test
/* export const apiUrlMr = "http://54.38.183.225:22000/api/v1";
export const apiUrlCiv = "http://54.38.183.225:22000/api/v1"; */
// export const apiUrlMr = "http://54.38.183.225:31000/api/v1";
// export const apiUrlCiv = "http://54.38.183.225:31000/api/v1";
export const apiUrlMr = "https://test.dev.betaclic.com:31001/api/v1";
export const apiUrlCiv = "https://test.dev.betaclic.com:31001/api/v1";
// Back end Pre Prod
// export const apiUrlCiv = "http://54.38.186.217:19000/api/v1";
// export const apiUrlCiv = "https://preprod.mirah.civ.betaclic.com:19001/api/v1";
// export const apiUrlMr = "http://54.38.187.241:19000/api/v1";
// export const apiUrlMr = "https://preprod.elevage.mr.betaclic.com:19001/api/v1";
// export default apiUrl;

// Back end Prod
// export const apiUrlMr = "https://elevage.mr.betaclic.com:19001/api/v1";
