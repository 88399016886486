import React, { useMemo } from "react";
import { AnimalProvider } from "context/animalContext";
import AnimalProfile from "layouts/gestionAnimaux/component/AnimalProfile";
import { useLocation /* , useNavigate */ } from "react-router-dom";
import BackButton from "components/BackButton";
import servicesAPI from "services/servicesAPI";
import LoadComponent from "components/LoadComponent";
import ModuleTitle from "components/ModuleTitle";

function DetailAnimal() {
  // const navigate = useNavigate();
  const { state } = useLocation();
  const { selectedData } = state;
  const { isAlert, isLoading, message, alertSeverity, handleLoad, getData, putData, postData } =
    servicesAPI();

  const loadDataConfig = useMemo(() => {
    const value = {
      isAlert,
      isLoading,
      message,
      alertSeverity,
      handleLoad,
    };
    return value;
  }, [isAlert, isLoading, message, alertSeverity]);
  const propsDataConfig = useMemo(() => {
    const value = {
      selectedData,
      title: `${selectedData?.isTranshumance ? "Transhumant" : ""}`,
      ApiService: { getData, putData, postData, handleLoad },
    };
    return value;
  }, [selectedData]);
  // console.log("selectedData?.isTranshumant", selectedData?.isTranshumance);
  return (
    <>
      <ModuleTitle title="Informations de l'animal" />
      <BackButton />

      <AnimalProvider propsDataConfig={propsDataConfig}>
        <AnimalProfile />
      </AnimalProvider>
      <LoadComponent loadData={loadDataConfig} />
    </>
  );
}

export default DetailAnimal;
