// lieuHelper.js
const handleLieuChange = (initialData, nameInput, valueInput) => {
  const defaultSelect = { label: "", region: "", departement: "", commune: "" };
  const updateValue = { ...initialData };
  // console.log("nameInput", nameInput);
  // console.log("valueInput", valueInput);
  /* if (nameInput === "region") {
    updateValue.lieu = defaultSelect;
  } else if (nameInput === "departement") {
    updateValue.lieu.departement = "";
    updateValue.lieu.commune = "";
  } else if (nameInput === "commune") {
    updateValue.lieu.commune = "";
  } */
  updateValue.lieu = defaultSelect;
  if (valueInput.reason === "clear") {
    updateValue[nameInput] = defaultSelect;
  } else {
    updateValue.lieu.region = valueInput?.newValue.region ?? "";
    updateValue.lieu.codeRegion = valueInput?.newValue.codeRegion ?? "";
    if (nameInput !== "region") {
      updateValue.lieu.departement = valueInput?.newValue.departement ?? "";
      updateValue.lieu.codeDepart = valueInput?.newValue.codeDepart ?? "";
      if (nameInput === "commune") {
        updateValue.lieu.commune = valueInput?.newValue.commune ?? "";
        updateValue.lieu.codeCommune = valueInput?.newValue.codeCommune ?? "";
        updateValue.lieu.id = valueInput?.newValue.id ?? "";
        updateValue.lieu = valueInput?.newValue;
      }
    }
  }

  return updateValue;
};
export default handleLieuChange;
