import { ERROR } from "constant/message";
import {
  ADMIN_PAYS_ROLE,
  ADMIN_DEPARTEMENTAL_ROLE,
  SUPER_ADMIN_ROLE,
  roleFonction,
} from "constant/role";

const { USER_DATA_STORAGE } = require("constant");

const userInfos = () => {
  try {
    const userDataStorage = localStorage.getItem(USER_DATA_STORAGE);
    let userInfo = {};
    if (userDataStorage !== null) {
      userInfo = JSON.parse(userDataStorage);
    }
    return userInfo;
  } catch (error) {
    console.log("userInfos eror", error);
    return ERROR;
  }
};
export default userInfos;
export const isSuperAdmin = () => {
  return userInfos().roles.some((i) => i.roleName === SUPER_ADMIN_ROLE);
};
export const isAdminGeneral = () => {
  return userInfos().roles.some((i) => i.roleName === ADMIN_PAYS_ROLE);
};
export const checkRoleExist = (roleName) => {
  return userInfos().roles.some((i) => i.roleName === roleName);
};
export const checkOnlyOneRoleExist = (roleName) => {
  return userInfos().roles.length === 1 && userInfos().roles.some((i) => i.roleName === roleName);
};

export const CurrentUserNiveau = () => {
  const userInfo = userInfos();
  // if (userInfo && userInfo.roles) {
  if (userInfo?.roles) {
    const roleUser = roleFonction.filter((value) =>
      userInfo.roles.map((role) => role.roleName).includes(value.role)
    );
    const roleNiveau = roleUser.reduce((item, currentItem) =>
      currentItem.niveau < item.niveau ? currentItem : item
    );

    return roleNiveau.niveau;
  }
  return [];
};
export const selectedUserNiveau = (roles) => {
  // if (userInfo && userInfo.roles) {
  if (roles.length > 0) {
    const roleUser = roleFonction.filter((value) =>
      roles.map((role) => role.roleName).includes(value.role)
    );
    const roleNiveau = roleUser.reduce((item, currentItem) =>
      currentItem.niveau < item.niveau ? currentItem : item
    );

    return roleNiveau.niveau;
  }
  return null;
};
export const CurrentUserLieu = () => {
  const userInfo = userInfos();
  // if (userInfo && userInfo.roles) {
  return userInfo?.lieu ?? {};
};
export const isAdminDepartemental = () => {
  return userInfos().roles.some((i) => i.roleName === ADMIN_DEPARTEMENTAL_ROLE);
};
