import DataTableAction from "components/DataTableRow/dataTableAction";
import DataTableContent from "components/DataTableRow/dataTableContent";
import { useState } from "react";
import { tableCellTextFormat } from "utils/textOperation";

export default function useEntite() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionOption, setActionOption] = useState("");
  const tableColumnsEntite = [
    { Header: "nom", accessor: "nom", align: "center" },
    { Header: "superviseur", accessor: "superviseur", align: "center" },
    { Header: "telephone", accessor: "telephone", align: "center" },
  ];
  const tableRowsEntite = (item) => {
    const row = {
      nom: <DataTableContent title1={tableCellTextFormat(item?.name, 50)} />,
      superviseur: (
        <DataTableContent
          title2={tableCellTextFormat(
            `${item?.superViseur?.prenom ?? ""} ${item?.superViseur?.nom ?? ""}`,
            15
          )}
        />
      ),
      telephone: <DataTableContent title1={tableCellTextFormat(item?.superViseur?.numTel, 50)} />,
    };
    return row;
  };

  const onSelectedRow = (item, action) => {
    // console.log("onSelectedRow", item, action);
    setSelectedItem(item);
    setActionOption(action);
  };
  const renderDataTable = (listData) => {
    const arrData = [];
    if (listData.length > 0) {
      const columnsActions = [{ Header: "action", accessor: "action", align: "center" }];
      setColumns([...tableColumnsEntite, ...columnsActions]);
      // setColumns(tableColumnsEntite);

      listData.map((i, idx, arr) => {
        let item = {};
        item = tableRowsEntite(i);
        arrData.push({
          ...item,
          ...{ action: <DataTableAction handleAction={onSelectedRow} item={i} /> },
        });
        if (idx === arr.length - 1) {
          setRows(arrData);
          return "success";
        }
        return "over";
      });
    } else {
      setRows(arrData);
      setColumns([]);
    }
  };
  return {
    renderDataTable,
    rows,
    columns,
    selectedItem,
    actionOption,
    onSelectedRow,
  };
}
