import React, { useContext, useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { UserContext } from "context/userContext";
import AutoCompleteComponent from "components/AutoCompleteComponent";
import getRole from "services/roleService";
import MDButton from "components/MDButton";
import { roleFonction, ADMIN_PAYS_ROLE, SUPER_ADMIN_ROLE } from "constant/role";
import useArrayOperation from "hooks/useArrayOperation";
import { ERROR } from "constant/message";
import { CurrentUserNiveau, checkRoleExist } from "utils/userInfos";

const { sortArray } = useArrayOperation();

const UserRole = () => {
  const { user, ApiService, handleLoad, canUpdate } = useContext(UserContext);
  const { getData, postData } = ApiService;
  const [listRole, setListRole] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [currentUserRole, setCurrentUserRole] = useState([]);
  const [newUserRole, setNewUserRole] = useState([]);
  const [removedUserRole, setRemovedUserRole] = useState([]);
  const fetchRoleData = async () => {
    const data = await getRole(getData);
    // console.log("dddaaa", data);
    const roleUser = data.map((i) => ({
      ...i,
      users: [
        {
          numTel: user?.numTel ?? "",
        },
      ],
    }));
    const niveauUser = CurrentUserNiveau();
    // console.log("ssss", roleUser, niveauUser);
    let filteredRolebyNiveau = roleUser;
    const isAdminPays = checkRoleExist(ADMIN_PAYS_ROLE);
    const isSuperAdmin = checkRoleExist(SUPER_ADMIN_ROLE);
    if (!isAdminPays && !isSuperAdmin) {
      filteredRolebyNiveau = roleUser.filter((x) => x.niveau > niveauUser);
    }
    setListRole(filteredRolebyNiveau);
  };
  const setCurrentRole = () => {
    const roleData = user?.roles.map((x) => {
      const fonctionData = roleFonction.find((y) => y.role === x.roleName);
      const item = {
        ...x,
        ...{ fonction: fonctionData?.fonction ?? x.roleName },
      };
      return item;
    });
    const currentRole = sortArray(roleData, "fonction");
    setUserRole(currentRole);
    setCurrentUserRole(currentRole);
  };
  // console.log("canUpdate role", canUpdate);
  useEffect(() => {
    setCurrentRole();
    fetchRoleData();
  }, []);
  const handleInputChange = (e) => {
    const { value } = e.target;
    const existingRoleName = new Set(currentUserRole.map((i) => i.roleName));
    const newRole = value.newValue.filter((i) => !existingRoleName.has(i.roleName));

    const uniqueRole = new Set();
    const selectedRole = value.newValue.filter((i) => {
      if (uniqueRole.has(i.roleName)) {
        return false; // Ne pas inclure cet élément (doublon)
      }
      uniqueRole.add(i.roleName);
      return true; // Inclure cet élément
    });

    setUserRole(selectedRole);
    setNewUserRole(newRole);
    // }
  };
  const handleRemoveRole = (roleToRemove) => {
    // const currentRoles = userRole || [];
    // console.log("handleRemoveRole", currentRoles);
    try {
      const UserRoleValue = userRole.filter((i) => i.roleName !== roleToRemove.roleName);
      const newRole = newUserRole.filter((i) => i.roleName !== roleToRemove.roleName);
      // const UserRoleValue = currentRoles.filter((i) => i.roleName !== roleToRemove.roleName);
      setUserRole(UserRoleValue);
      setNewUserRole(newRole);
      const deletedRole = currentUserRole.filter((i) => i.roleName === roleToRemove.roleName);
      // const deletedRole = [...removedUserRole, ...deletedRoleValue];
      setRemovedUserRole(deletedRole);
    } catch (error) {
      console.log("handleRemoveRole error", error);
    }
    // console.log("roleToRemove", roleToRemove);
  };
  const deleteRole = async () => {
    // const endpoint = "Roles/removeRolesFromUser";
    const endpoint = "Roles/user/remove";
    const dataForm = { numTel: user.numTel, roles: removedUserRole };
    const fetchData = await postData(endpoint, dataForm);
    // console.log("deleteRole dddd", fetchData, dataForm);
    if (fetchData !== ERROR) {
      const loadData = {
        isAlert: true,
        message: "Modification effectuée avec succès",
        isLoading: false,
        alertSeverity: "success",
      };
      handleLoad(loadData);
    }
  };
  const saveRole = async () => {
    const endpoint = "Roles/assignUsersRoles";
    const dataForm = newUserRole;
    const fetchData = await postData(endpoint, dataForm);
    console.log("ddd", fetchData);
    if (removedUserRole.length > 0) {
      deleteRole();
    } else {
      const loadData = {
        isAlert: true,
        message: "Modification effectuée avec succès",
        isLoading: false,
        alertSeverity: "success",
      };
      handleLoad(loadData);
    }
  };
  const handleUpdateRole = async () => {
    if (!user.enabled) {
      const loadData = {
        isAlert: true,
        message: "Impossible de modifier le la fonction d'un utilisateur bloqué",
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
    } else if (newUserRole.length > 0) {
      saveRole();
    } else if (removedUserRole.length > 0) {
      deleteRole();
    }
  };

  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <Box textAlign="center">
        <Typography variant="h6">Fonction de l&apos;utilisateur</Typography>
      </Box>
      <AutoCompleteComponent
        options={listRole}
        name="roles"
        label="Fonctions"
        value={userRole}
        onChange={handleInputChange}
        isResultObject
        isMultiSelect
        onRemove={handleRemoveRole}
        isDisabled={!canUpdate}
      />
      {canUpdate && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <MDButton type="submit" variant="gradient" color="info" onClick={handleUpdateRole}>
            Enregistrer
          </MDButton>
        </Box>
      )}
    </Box>
  );
};

export default UserRole;
