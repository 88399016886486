// src/context/UserContext.js
import React, { createContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  SUPER_ADMIN_ROLE,
  /* ADMIN_PAYS_ROLE,
  ADMIN_DEPARTEMENTAL_ROLE,
  ADMIN_REGION_ROLE,
  RESPONSABLE_FERME, */
  ADMIN_PAYS_ROLE,
} from "constant/role";
import { MODULE_USER } from "constant/moduleInfo";

import CurrentUserRole from "utils/currentUserRole";
import { CurrentUserNiveau, selectedUserNiveau } from "utils/userInfos";

export const UserContext = createContext();

export const UserProvider = ({ propsDataConfig, children }) => {
  const [user, setUser] = useState(propsDataConfig.selectedData);
  // const [niveauSelectedUser, setNiveauSelectedUser] = useState(0);
  const [canUpdate, setCanUpdate] = useState(false);
  /* const allowedRoles = [
    SUPER_ADMIN_ROLE,
    ADMIN_PAYS_ROLE,
    ADMIN_DEPARTEMENTAL_ROLE,
    ADMIN_REGION_ROLE,
    RESPONSABLE_FERME,
  ]; */
  const niveau = CurrentUserNiveau();
  // const canUpdate = allowedRoles.some((role) => CurrentUserRole()?.includes(role));
  useEffect(() => {
    if (user !== undefined) {
      const niveauSelectedUser = selectedUserNiveau(user.roles);
      // console.log("canUpdate usercontexte", niveauSelectedUser > niveau);
      /* console.log("niveauSelectedUser > niveau", niveauSelectedUser > niveau);
      console.log(
        "CurrentUserRole()?.includes(SUPER_ADMIN_ROLE)",
        CurrentUserRole()?.includes(SUPER_ADMIN_ROLE)
      );
      console.log(
        "CurrentUserRole()?.includes(ADMIN_PAYS_ROLE)",
        CurrentUserRole()?.includes(ADMIN_PAYS_ROLE)
      ); */
      if (
        niveauSelectedUser > niveau ||
        CurrentUserRole()?.includes(SUPER_ADMIN_ROLE) ||
        CurrentUserRole()?.includes(ADMIN_PAYS_ROLE)
      ) {
        setCanUpdate(true);
      }
    } else {
      console.log("add user");
      if (propsDataConfig?.permissionModule.find((i) => i.name.includes(`Insert${MODULE_USER}`))) {
        setCanUpdate(true);
      }
    }
  }, []);
  const updateUserDetails = (updatedDetails) => {
    setUser((prevUser) => ({ ...prevUser, ...updatedDetails }));
  };

  const updateUserRole = (newRole) => {
    setUser((prevUser) => ({ ...prevUser, role: newRole }));
  };
  const contextValue = useMemo(() => {
    const value = {
      user,
      niveau: propsDataConfig.niveau,
      roleTitle: propsDataConfig.roleTitle,
      updateUserDetails,
      updateUserRole,
      ApiService: propsDataConfig.ApiService,
      handleLoad: propsDataConfig.handleLoad,
      canUpdate,
    };
    return value;
  }, [user, canUpdate]);
  // console.log("sss", user);
  // console.log("niveau", niveau);
  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
// Validation des props avec PropTypes
UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
  propsDataConfig: PropTypes.object.isRequired,
};
